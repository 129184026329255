var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "outsideclick",
          rawName: "v-outsideclick",
          value: _setup.handleClickOutSide,
          expression: "handleClickOutSide",
        },
      ],
      staticClass: "languageList_wrap",
    },
    [
      _c("div", { staticClass: "languageList_content" }, [
        _c("div", { staticClass: "languageList_arrow" }),
        _vm._v(" "),
        _c("div", { staticClass: "language_list_wrap" }, [
          _c(
            "div",
            { staticClass: "language_list" },
            _vm._l(_setup.languageList, function (l) {
              return _c(
                "a",
                {
                  key: l.lcid,
                  staticClass: "top_shopping_language_item",
                  on: {
                    click: function ($event) {
                      return _setup.gotoLanguage(l)
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "language_icon",
                    class: [`icon_${l.lanCode}`],
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "language_name" }, [
                    _vm._v(_vm._s(l.name)),
                  ]),
                  _vm._v(" "),
                  _setup.currentLan.lcid === l.lcid
                    ? _c(
                        "svg",
                        {
                          staticClass: "language_tick",
                          attrs: { viewBox: "0 0 16 16" },
                        },
                        [
                          _c("g", [
                            _c("path", {
                              attrs: {
                                d: "M6.5,12a1,1,0,0,1-.71-.29l-3-3A1,1,0,1,1,4.21,7.29L6.5,9.59l5.29-5.3a1,1,0,1,1,1.42,1.42l-6,6A1,1,0,0,1,6.5,12Z",
                                fill: "currentColor",
                              },
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }