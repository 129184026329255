import { getComputedTextColor } from '@/components/utils';
import { getIsOpenRemoveUrlArg, getUrlHashInCookie } from '@/site/shared/cookie/index.js';
import { Comm } from '../../utils';
import { store } from '@/store/store';

function addClass(classArr = [], className = '') {
    if (classArr.indexOf(className) === -1) {
        classArr.push(className);
    }
}

function addClasses(classArr = [], ...classNames) {
    classNames.forEach((className) => addClass(classArr, className));
}

// 自定链接时，需要校对站内链接的colId
function verifyCusLinkType() {
    var anchorStr = getIsOpenRemoveUrlArg() ? getUrlHashInCookie() : Fai.top.location.hash,
        checkArr = ['_pp', '_np', '_php'],
        tmpParam = '',
        selectColId = -1;

    if (anchorStr) {
        checkArr.forEach(function (val) {
            if (anchorStr.includes('#')) {
                anchorStr = anchorStr.replace(/#/g, '&').replace(/&/, '#');
            }
            tmpParam = Fai.getHashParam(anchorStr, val);
            if (tmpParam && tmpParam.split('_').length) {
                selectColId = tmpParam.split('_')[0];
            }
        });
    }
    return parseInt(selectColId);
}

function hasChildByRow(rowId, rows) {
    let row = rows[`row${rowId}`];
    if (!row) {
        return false;
    }
    let cols = row.cols;
    for (let i = 0; i < cols.length; i++) {
        let col = cols[i];
        if (col.mIds && col.mIds.length) {
            return true;
        }
        let rIds = col.rIds;
        if (rIds && rIds.length) {
            let flag = false;
            for (let j = 0; j < rIds.length; j++) {
                flag = hasChildByRow(rIds[j], rows);
                if (flag) {
                    return flag;
                }
            }
        }
    }
    return false;
}

function createNavNameFontStyle(fontStyle = {}, { selected = false, passDefault = true } = {}) {
    let style = {};
    if ((fontStyle && fontStyle.y == 1) || !passDefault) {
        // 字体
        fontStyle.s !== undefined && (style.fontSize = fontStyle.s + 'px');
        fontStyle.f !== undefined && (style.fontFamily = fontStyle.f);
        fontStyle.i !== undefined && (style.fontStyle = fontStyle.i == 1 ? 'italic' : 'normal');
        fontStyle.w !== undefined && (style.fontWeight = fontStyle.w == 1 ? 'bold' : 'normal');
        fontStyle.d !== undefined && (style.textDecoration = fontStyle.d == 1 ? 'underline' : 'none');

        store.getters.addGlobalFontFamily(style, fontStyle);
        // 颜色
        if (fontStyle.sc != undefined) {
            Comm.extend(style, getComputedTextColor(fontStyle.c));
            if (selected === true) {
                Comm.extend(style, getComputedTextColor(fontStyle.sc));
            } else {
                Comm.extend(style, getComputedTextColor(fontStyle.c));
            }
            style.activeColor = getComputedTextColor(fontStyle.sc);
        }
    }

    return style;
}

export { addClass, addClasses, verifyCusLinkType, hasChildByRow, createNavNameFontStyle };
