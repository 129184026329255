import { debounce, digGet, throttle } from '@jz/utils';
import { JzFdpLog } from '@/site/shared/log/index.js';
import { getState } from '../store';
const FIX_TOP_TEMP_CLASS = 'fix_top_class';
let navRowId = 0; // 记录导航 rowId，避免重复查找

const FIX_TOP_HEADER_SETTING = 'jz_fixed_top';
const FIX_TOP_GRID_HEADER_CLASS = 'g_header_content_temp__fixed';

// 发送埋点
const sendFixTopFdp = (eventName, freeText) => {
    if (window._store.state.manageMode) {
        let jz_zsy_page = '常规栏目';
        switch (window._store.state.fullScreen) {
            case 1:
                jz_zsy_page = '纵向滚屏';
                break;
            case 2:
                jz_zsy_page = '横向滚屏';
                break;
            default:
                break;
        }
        JzFdpLog.logJzFdp(eventName, {
            jz_content_terminal: '自适应',
            jz_free_text1: freeText,
            jz_version: window._store.state.jzVersion,
            jz_zsy_page,
        });
    }
};

const isHtmlElement = (el) => {
    return el && el.nodeType == Node.ELEMENT_NODE;
};

// 获取目标节点所在容器的rowId
const findParentRowId = (el, endParent = document.body) => {
    if (!isHtmlElement(el)) return 0;
    let parentNode = el.parentNode;
    if (parentNode != null) {
        let isRow = parentNode.classList.contains('jz_web_row');
        if (isRow) {
            let arr = parentNode.id.split('row');
            return arr[arr.length - 1];
        } else {
            // eslint-disable-next-line no-unused-vars
            return parentNode == endParent ? 0 : findParentRowId(parentNode, endParent);
        }
    } else {
        return 0;
    }
};

// 判断目标节点是否在导航所在通栏，语言导航、网站标题、文本模块
const isElInNavRow = (rowId) => {
    let navEl = $('#gridHeader .jz_nav_wrap')[0];
    if (navEl == null) {
        navRowId = 0;
    } else {
        if (navRowId == 0) {
            navRowId = findParentRowId(navEl);
        }
    }
    if (navRowId != 0) {
        return rowId == navRowId;
    } else {
        return false;
    }
};

// 检测当前 顶部是否是固定状态
const currentPageIsFixed = () => {
    // 普通栏目
    let isFullScreen = getState('fullScreen');

    if (window._store.state.manageMode) {
        let fixTop = isFullScreen
            ? digGet(window._store.state.responsiveInfo, ['pattern', 'rollingScreen', 'fixedTop'])
            : digGet(window._store.state.responsiveInfo, ['pattern', 'header', 'fixedTop']);
        return fixTop;
    } else {
        return isFullScreen ? window._store.state.fullScreenOpenFixed : window._store.state.header.isOpenFixedTop;
    }
};

const getHeaderModuleIds = () => {
    let moduleDomList = Array.from($('#gridHeader .jz_module'));
    return moduleDomList.map((item) => {
        return parseInt($(item).attr('projectid')) || 0;
    });
};

// 判断当前模块是否在顶部中
const moduleInHeader = (moduleId) => {
    let moduleIdList = getHeaderModuleIds();
    return moduleIdList.includes(moduleId);
};

const getFixedText = (fts, option = {}) => {
    if (fts.t == 0) {
        return { css: {}, cls: [] };
    }
    let { c = '', d = 0, i = 0, w = 0 } = fts.f;
    let { renderColor = true } = option;
    let css = {
        '--fixed-textDecoration': d == 0 ? 'none' : 'underline',
        '--fixed-fontStyle': i == 0 ? 'normal' : 'italic',
        '--fixed-fontWeight': w == 0 ? 'normal' : 'bold',
    };

    let cls = ['cus_fixed_top'];
    // 颜色若为普通颜色，则设置color；若颜色为渐变色，则设置background-image
    if (renderColor) {
        if (c) {
            css['--fixed-color'] = c;
            if (c.indexOf('linear-gradient') > -1) {
                cls.push('c-isGradientText');
            }
        }
        if (option.sc) {
            css['--fixed-sc-color'] = fts.f.sc;
            if (c.indexOf('linear-gradient') > -1) {
                cls.push('sc-isGradientText');
            }
        }
    }
    return { css, cls };
};

const getComputedTextlinearGradientColor = (color, useNew = true, needImportant = true) => {
    let obj = {};
    let important = needImportant ? '!important' : '';
    // 进行颜色格式化
    if (!useNew) {
        let colorCssKeys = ['background-image', 'color', '-webkit-background-clip', '-webkit-text-fill-color'];
        colorCssKeys.forEach((key) => {
            obj[key] = '';
        });
    } else {
        if (color != null && color != '') {
            if (color.indexOf('linear-gradient') > -1) {
                obj['background-image'] = `${color} ${important}`;
                obj['-webkit-background-clip'] = `text ${important}`;
                obj['-webkit-text-fill-color'] = `transparent ${important}`;
                obj['color'] = '';
            } else {
                obj['color'] = `${color} ${important}`;
                obj['background-image'] = `none ${important}`;
                obj['-webkit-background-clip'] = `unset ${important}`;
                obj['-webkit-text-fill-color'] = `unset ${important}`;
            }
        }
    }
    return obj;
};

// 获取当前滚动高度
const getScrollTop = () => {
    return $(document).scrollTop();
};

// 顶部样式更新
const updateFixTopStyle = () => {
    let scroH = getScrollTop(); //滚动高度
    if (scroH < 10) {
        window._store.commit('header/setScrollToTop', true);
    } else {
        window._store.commit('header/setScrollToTop', false);
    }
};

const updateFullScreenFixTopStyle = (curIndex = 0) => {
    if (curIndex == 0) {
        window._store.commit('header/setScrollToTop', true);
    } else {
        window._store.commit('header/setScrollToTop', false);
    }
};

// 固定顶部绑定滚动事件，触发顶部样式更新
const bindScrollForFixTopStyle = () => {
    setTimeout(() => {
        updateFixTopStyle();
    }, 200);
    $(document).off('scroll.fixTopStyle').on('scroll.fixTopStyle', debounce(updateFixTopStyle, 50));
};

const typeIsInNavRow = (type, moduleId) => {
    let rowId = 0;
    let isNavRow = false;
    if (window._store.state.header.isOpenFixedHeight) {
        if (type == 'title') {
            let titleEl = $('#gridHeader .jz_website_title');
            rowId = findParentRowId(titleEl[0]);
        } else if (type == 'language') {
            let languageEl = $('#gridHeader .s_multi_language_container');
            rowId = findParentRowId(languageEl[0]);
        } else if (type == 'module') {
            let moduleEl = $(`#module${moduleId} .module_text_content`);
            rowId = findParentRowId(moduleEl[0]);
        } else if (type == 'nav') {
            isNavRow = true;
        }
        if (isElInNavRow(rowId)) {
            isNavRow = true;
        }
    } else {
        return true;
    }
    return isNavRow;
};

const bindMouseEnterHeaderEvent = () => {
    const handler = throttle(function (e) {
        const el = document.querySelector('#gridHeader .g_header_content');
        if (!el) return;
        const headerElRect = el.getBoundingClientRect();
        const elInHeader = $(e.target).parents('#gridHeader').length > 0 === true;
        let enter =
            e.clientX >= headerElRect.left &&
            e.clientX <= headerElRect.right &&
            e.clientY >= headerElRect.top &&
            e.clientY <= headerElRect.bottom;

        enter = enter || elInHeader;
        const isFixed = $('#gridHeader').hasClass('g_header__fixed');

        if (enter === true) {
            // mouseInHeader = true;
            // FixTopStyleHandler.update();
        }
        if (enter === false && isFixed === false) {
            // mouseInHeader = false;
            // FixTopStyleHandler.reset();
        }
    }, 50 /** default */);

    $(document).off('mousemove.fixTopStyle').on('mousemove.fixTopStyle', handler);
};
const unbindMouseEnterHeaderEvent = () => {
    $(document).off('mousemove.fixTopStyle');
};

export {
    moduleInHeader,
    getComputedTextlinearGradientColor,
    bindScrollForFixTopStyle,
    sendFixTopFdp,
    currentPageIsFixed,
    updateFullScreenFixTopStyle,
    findParentRowId,
    isElInNavRow,
    typeIsInNavRow,
    FIX_TOP_GRID_HEADER_CLASS,
    FIX_TOP_HEADER_SETTING,
    FIX_TOP_TEMP_CLASS,
    bindMouseEnterHeaderEvent,
    unbindMouseEnterHeaderEvent,
    getFixedText,
};
