import jzRequest from '@jz/request';

let loginPopup = null;

function getLoginData() {
    return jzRequest
        .get('/rajax/member_h.jsp?cmd=getWafNotCk_getLoginAndSignupData', {
            params: {
                url: encodeURIComponent(location.href),
            },
        })
        .then((res) => res.data);
}

export async function createLoginPopup() {
    let device = window._store.state.device;
    let mobi = device == 'mobi';
    let overflowElStr = mobi ? '.jz_preview_area, html' : 'html';
    let appendElStr = mobi ? '.jz_preview_area' : 'body';

    const htmlStr = `
    <div id="loginPopup"></div>
  `;

    const removeDialog = () => {
        $('#loginPopup').remove();
        $(overflowElStr).css('overflow', '');
        loginPopup = null;
    };

    if (loginPopup != null) {
        removeDialog();
    }

    $(htmlStr).appendTo(appendElStr);
    $(overflowElStr).css('overflow', 'hidden');
    let loginModuleData = {
        id: 'loginDialog',
        data: {},
    };
    let popupComponent = null;
    try {
        let { loginData } = await getLoginData();
        loginModuleData.data = loginData;
        const { default: component } = await import(/* webpackChunkName: "loginPopup" */ './popup.vue');
        popupComponent = component;
    } catch (err) {
        console.log(err);
    }
    loginPopup = new Vue({
        el: '#loginPopup',
        store: window._store,
        render: (h) =>
            h(popupComponent, {
                props: {
                    loginModuleData: loginModuleData,
                    pc: device == 'pc',
                },
                on: {
                    close: () => {
                        removeDialog();
                    },
                },
            }),
    });
}
