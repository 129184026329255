import request from '@jz/request';

export function genLIde(data) {
    return request.post('/rajax/linkChg_h.jsp?cmd=getWafNotCk_genLIde', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function delJumpServ(data = {}) {
    return request.post('/rajax/linkChg_h.jsp?cmd=delWafCk_delJumpServ', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getColTabPackModuleList(params) {
    return request.get('/api/rmanage/linkChg/getColTabPackModuleList', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getJumpServ(data = {}) {
    return request.post('/rajax/linkChg_h.jsp?cmd=getWafNotCk_getJumpServ', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getJumpInfo(data = {}) {
    return request.post('/ajax/module_h.jsp?cmd=getWafNotCk_getJumpInfo', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getAnchorList(params = {}) {
    return request.get('/rajax/linkChg_h.jsp?cmd=getWafNotCk_getAnchorList', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getShoppingCartInfo(openCartType = 0) {
    return request
        .get('/ajax/linkChg_h.jsp?cmd=getWafNotCk_getShoppingCartInfo', {
            params: {
                openCartType,
            },
        })
        .then(({ data = {} }) => {
            return data;
        });
}
