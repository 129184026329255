var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "mobi_shopping_cart_icon",
      attrs: { href: _setup.href, target: _setup.target, rel: _setup.rel },
      on: { click: _setup.handleIconClick },
    },
    [
      _c(
        "svg",
        {
          staticClass: "w-full h-full",
          style: { color: _setup.mobiIconColor },
          attrs: { viewBox: "0 0 24 24", width: "128", height: "128" },
        },
        [
          _c("g", [
            _c("path", {
              attrs: {
                fill: "currentColor",
                d: "M7.14,19.08A1.62,1.62,0,1,1,5.52,20.7a1.62,1.62,0,0,1,1.62-1.62",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                fill: "currentColor",
                d: "M18.78,19.08a1.62,1.62,0,1,1-1.62,1.62,1.62,1.62,0,0,1,1.62-1.62",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                fill: "currentColor",
                d: "M22.28,5.79a3,3,0,0,0-2.34-.94H5.87l-.09-.9C5.52,2.27,4.54,1.68,2.94,1.68H2A1,1,0,0,0,2,3.62h1a.84.84,0,0,1,1,.69l.2,2,.71,9.38A3.06,3.06,0,0,0,8,18.43H18.93a3.12,3.12,0,0,0,2.95-2.7L23,8.18a3,3,0,0,0-.69-2.39M21,7.94,20,15.42c-.1.53-.49,1.07-1,1.07H8c-.51,0-1.24-.28-1.27-.92L6.08,6.79H19.94a1.22,1.22,0,0,1,.9.3,1.15,1.15,0,0,1,.21.86",
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _setup.shoppingCartSizeText != 0
        ? _c("div", { staticClass: "shopping_cart_count" }, [
            _vm._v(_vm._s(_setup.shoppingCartSizeText)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }