var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "s_member_login_mobi",
      class: _vm.loginedClass,
      attrs: { href: _vm.loginUrl },
    },
    [
      _c(
        "svg",
        {
          staticClass: "member_login_svg",
          style: { color: _vm.mobiIconColor },
        },
        [_c("use", { attrs: { "xlink:href": "#icon_members" } })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }