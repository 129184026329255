var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hovermenu",
          rawName: "v-hovermenu",
          value: _vm.hovermenu,
          expression: "hovermenu",
        },
      ],
      staticClass: "jz_banner",
      class: [`transition_type_${_vm.banner.sa}`],
      attrs: { id: "jz_banner" },
      on: {
        dblclick: function ($event) {
          $event.stopPropagation()
          return _vm.editBanner.apply(null, arguments)
        },
      },
    },
    [
      !_vm.isFlvBanner
        ? _c(
            "div",
            { staticClass: "banner_pic", style: _vm.bannerZoneSize },
            [
              _c("jz-banner-btn", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.picData.length > 1,
                    expression: "picData.length > 1",
                  },
                ],
                attrs: {
                  "btn-style": _vm.banner.s,
                  "pic-data": _vm.picData,
                  "is-pc": _vm.isPc,
                },
                on: { prev: _vm.prev, next: _vm.next },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "banner_pic_group",
                  style: _vm.bannerHeightSize,
                },
                _vm._l(_vm.picData, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "banner_pic_item_wrap arrow_img",
                      class: "banner_pic_" + index,
                    },
                    [
                      _c("a", {
                        staticClass: "banner_pic_item_link",
                        attrs: {
                          target: item.ib ? "_blank" : "_self",
                          href: item.url || null,
                          onclick: _vm.jumpOnclickStr(item),
                        },
                        on: {
                          click: _vm.handleLinkClick,
                          dragstart: (e) => e.preventDefault(),
                        },
                      }),
                      _vm._v(" "),
                      item.st === 1
                        ? _c(
                            "div",
                            { staticClass: "banner_video_item" },
                            [
                              !_vm.isSupportAutoPlay
                                ? _c("div", {
                                    staticClass: "flv_play_btn",
                                    on: { click: _vm.play },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("video", {
                                ref: "VideoWrapperMixed",
                                refInFor: true,
                                staticClass:
                                  "video-js vjs-default-skin vjs-big-play-centered",
                                attrs: {
                                  id: "VideoWrapperMixed",
                                  src: item.psrc,
                                  playsinline: "",
                                  "x5-playsinline": "",
                                  "webkit-playsinline": "",
                                  disablePictureInPicture: "",
                                  muted: "",
                                  loop: "",
                                  poster: _vm.poster,
                                  autoplay: "",
                                },
                                domProps: { muted: true },
                                on: { play: _vm.playHandler },
                              }),
                              _vm._v(" "),
                              _vm.muteBtn.open && !_vm.isMobi
                                ? _c("videoMuteBtn", {
                                    attrs: {
                                      "is-video-mute": _vm.isVideoMute,
                                      "mute-btn": _vm.muteBtn,
                                    },
                                    on: { toggleMute: _vm.toggleMute },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "banner_pic_item",
                          style: _vm.getItemStyle(item),
                        },
                        [
                          !(_vm.isMobi && item.st === 1)
                            ? _c("jz-web-rows", {
                                staticClass: "banner_module_zone",
                                attrs: { ids: [item.prid], name: "webBanner" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFlvBanner
        ? [
            _vm.muteBtn.open && !_vm.isMobi
              ? _c("videoMuteBtn", {
                  attrs: {
                    "mute-btn": _vm.muteBtn,
                    "is-video-mute": _vm.isVideoMute,
                  },
                  on: { toggleMute: _vm.toggleMute },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isSupportAutoPlay
              ? _c("div", {
                  staticClass: "flv_play_btn",
                  on: { click: _vm.play },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "banner_flv" }, [
              _c("div", { staticClass: "banner_flv_background" }, [
                _c("video", {
                  ref: "VideoWrapper",
                  staticClass:
                    "video-js vjs-default-skin vjs-big-play-centered",
                  attrs: {
                    id: "jz_banner_flv",
                    playsinline: "",
                    "x5-playsinline": "",
                    "webkit-playsinline": "",
                    src: _vm.flvSrc,
                    "data-original": _vm.flvUrl,
                    disablePictureInPicture: "",
                    muted: "",
                    loop: "",
                    poster: _vm.poster,
                    autoplay: "",
                  },
                  domProps: { muted: true },
                  on: { play: _vm.playHandler },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "banner_flv_area", style: _vm.bannerFlvSize },
                [
                  !_vm.isMobi
                    ? _c("jz-web-rows", {
                        staticClass: "banner_module_zone",
                        attrs: { ids: [_vm.banner.frid], name: "webBanner" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }