
export default {
    name: 'ShoppingCartItemIcon',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getDefaultIcon() {
            let icon = {
                1: (
                    <svg class="w-full h-full" viewBox="0 0 20 20" width="128" height="128">
                        <rect
                            x="2"
                            y="8"
                            width="4"
                            height="6"
                            rx="2"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        ></rect>
                        <rect
                            x="14"
                            y="8"
                            width="4"
                            height="6"
                            rx="2"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        ></rect>
                        <path
                            d="M4,8a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        ></path>
                        <path
                            d="M16,14h0a4,4,0,0,1-4,4H10"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                        ></path>
                    </svg>
                ),
            }[this.item.style] || (
                <svg class="w-full h-full" viewBox="0 0 20 20" width="128" height="128">
                    <rect
                        x="3"
                        y="3"
                        width="5"
                        height="5"
                        rx="1"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    ></rect>
                    <rect
                        x="12"
                        y="3"
                        width="5"
                        height="5"
                        rx="1"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    ></rect>
                    <rect
                        x="3"
                        y="12"
                        width="5"
                        height="5"
                        rx="1"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    ></rect>
                    <rect
                        x="12"
                        y="12"
                        width="5"
                        height="5"
                        rx="1"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    ></rect>
                </svg>
            );
            switch (this.item.type) {
                case 1:
                    icon = (
                        <svg class="w-full h-full" viewBox="0 0 20 20" width="128" height="128">
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 3A6 6 0 1 0 9 15A6 6 0 1 0 9 3Z"
                            ></path>
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17 17L14 14"
                            ></path>
                        </svg>
                    );
                    break;

                case 2:
                    icon = (
                        <svg class="w-full h-full" viewBox="0 0 20 20" width="128" height="128">
                            <rect
                                x="7"
                                y="3"
                                width="6"
                                height="7"
                                rx="3"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            ></rect>
                            <path
                                d="M15.63,18H4.37a1.61,1.61,0,0,1-1.49-2.2L3,15.48A3.94,3.94,0,0,1,6.67,13h6.66A3.94,3.94,0,0,1,17,15.48l.13.32A1.61,1.61,0,0,1,15.63,18Z"
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            ></path>
                        </svg>
                    );
                    break;
                case 3:
                    icon = (
                        <svg class="w-full h-full" viewBox="0 0 20 20" width="128" height="128">
                            <path
                                d="M12.9,17H4.1C3.4,17,3,16.6,3,15.9V7.1C3,6.4,3.4,6,4.1,6h8.8C13.6,6,14,6.4,14,7.1v8.8 C14,16.6,13.6,17,12.9,17z"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                d="M7,6V4c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1v8c0,0.6-0.4,1-1,1h-2"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                            ></path>
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                d="M7 13L10 13"
                            ></path>
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-miterlimit="10"
                                d="M6 14L8 9 9 9 11 14"
                            ></path>
                        </svg>
                    );
                    break;
                case 4:
                    icon = (
                        <svg class="w-full h-full" viewBox="0 0 20 20" width="128" height="128">
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M2 3L3 3 5 13 16 13 18 5 4 5"
                            ></path>
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 16A1 1 0 1 0 5 18A1 1 0 1 0 5 16Z"
                            ></path>
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 16A1 1 0 1 0 15 18A1 1 0 1 0 15 16Z"
                            ></path>
                        </svg>
                    );
                    break;
            }
            return icon;
        },
        getFontIcon(item) {
            return item.isPic || this.$isServer ? '' : '&#x' + item.fontPic;
        },
    },
    render() {
        let icon = null;
        if (!this.item.fontPic && !this.item.picPath) {
            icon = this.getDefaultIcon();
        } else {
            if (this.item.isPic) {
                icon = (
                    <img
                        class="w-full h-full object-contain object-center"
                        src={this.item.picPath}
                        alt={this.item.name}
                    />
                );
            } else {
                icon = (
                    <i
                        class="icon-icon text-[20px] w-full h-full flex-center"
                        domPropsInnerHTML={this.getFontIcon(this.item)}
                    ></i>
                );
            }
        }

        return (
            <div class="w-full h-full">
                {icon}
                <div>{this.$slots.default}</div>
            </div>
        );
    },
};
