export const hoverStatus = {
    bind(el, { expression }, { context }) {
        el.__vHoverStatusMouseenter = () => (context[expression] = true);
        el.__vHoverStatusMouseleave = () => (context[expression] = false);

        el.addEventListener('mouseenter', el.__vHoverStatusMouseenter, false);
        el.addEventListener('mouseleave', el.__vHoverStatusMouseleave, false);
    },
    unbind(el) {
        el.removeEventListener('mouseenter', el.__vHoverStatusMouseenter, false);
        el.removeEventListener('mouseleave', el.__vHoverStatusMouseleave, false);
        delete el.__vHoverStatusMouseenter;
        delete el.__vHoverStatusMouseleave;
    },
};
