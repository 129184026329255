import { createLoginPopup } from '@/site/shared/loginPopup/index';
import { getShoppingCartInfo } from '@/api/link';
import { warningMessage } from '@/site/shared/dialog/index.js';

let loading = false;
export async function openShoppingCart({ openCartType = 0 } = {}) {
    if (loading) {
        return;
    }
    loading = true;
    const shoppingCartInfo = await getShoppingCartInfo(openCartType);
    loading = false;

    if (!shoppingCartInfo.openShoppingCart) {
        return;
    }

    if (window._store.state.manageMode) {
        warningMessage('请到预览态查看购物车效果', true);
        return;
    }

    if (shoppingCartInfo.success === false) {
        console.error(shoppingCartInfo.msg || '获取购物车信息失败');
        return;
    }

    if (!shoppingCartInfo.isMemberLogin) {
        createLoginPopup();
        return;
    }

    if (openCartType === 0) {
        if (jzUtils.browser.isSafari() || jzUtils.browser.isIos()) {
            // ios safari浏览器下，直接打开购物车页面会被拦截，setTimeout可以解决
            setTimeout(() => {
                window.open(shoppingCartInfo.mcartUrl);
            }, 0);
        } else {
            window.open(shoppingCartInfo.mcartUrl);
        }
        return;
    }

    const { shoppingCartPageInfo } = shoppingCartInfo;

    const ShoppingCartPopup = await import(
        /* webpackChunkName: "shoppingCartPopup" */ '@/site/shoppingCart/shoppingCartPopup.vue'
    );
    const id = 'shopping_cart_popup_popup';
    const cartDiv = document.createElement('div');
    cartDiv.id = id;
    document.body.appendChild(cartDiv);
    const vm = new Vue({
        store: window._store,
        render(h) {
            return h(ShoppingCartPopup.default, {
                props: {
                    shoppingCartPageInfo,
                },
                on: {
                    close: () => {
                        document.body.removeChild(this.$el);
                        vm.$destroy();
                    },
                },
            });
        },
    }).$mount(`#${id}`);
}
