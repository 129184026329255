var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hidden
    ? _c(
        "div",
        {
          directives: [
            {
              name: "contextmenu",
              rawName: "v-contextmenu",
              value: _vm.contextmenu,
              expression: "contextmenu",
            },
            {
              name: "lazyload2",
              rawName: "v-lazyload2",
              value:
                !_vm.isWebRowBg && !_vm.isShowVideoBg && _vm.rowBgImgSrc
                  ? { id: _vm.row.id, src: _vm.rowBgImgSrc }
                  : {},
              expression:
                "!isWebRowBg && !isShowVideoBg && rowBgImgSrc ? { id: row.id, src: rowBgImgSrc } : {}",
            },
          ],
          ref: "webRowLine",
          staticClass: "jz_web_row_line",
          class: [
            _vm.lineClasses,
            `${!_vm.isWebRowBg && !_vm.isShowVideoBg ? "jz_web_row_bg" : ""}`,
          ],
          style: _vm.rowLineCss,
          attrs: {
            id: `rowline${_vm.row.id}`,
            "data-stellar-background-ratio": _vm.isOpenAdaptWidthFullBg
              ? _vm.dataStellarBackgroundRatio
              : null,
          },
          on: { dblclick: _vm.editRow },
        },
        [
          _vm.isShowVideoBg && !_vm.isWebRowBg
            ? _c("row-video-bg", { attrs: { row: _vm.row } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "hovermenu",
                  rawName: "v-hovermenu",
                  value: _vm.hovermenu,
                  expression: "hovermenu",
                },
                {
                  name: "lazyload2",
                  rawName: "v-lazyload2",
                  value:
                    _vm.isWebRowBg && !_vm.isShowVideoBg && _vm.rowBgImgSrc
                      ? { id: _vm.row.id, src: _vm.rowBgImgSrc }
                      : {},
                  expression:
                    "isWebRowBg && !isShowVideoBg && rowBgImgSrc ? { id: row.id, src: rowBgImgSrc } : {}",
                },
              ],
              ref: "webRow",
              staticClass: "jz_web_row",
              class: [
                _vm.classes,
                `${
                  _vm.isWebRowBg && !_vm.isShowVideoBg ? "jz_web_row_bg" : ""
                }`,
              ],
              style: _vm.rowStyle,
              attrs: {
                id: "row" + _vm.row.id,
                "data-stellar-background-ratio": !_vm.isOpenAdaptWidthFullBg
                  ? _vm.dataStellarBackgroundRatio
                  : null,
              },
            },
            [
              _vm.isShowVideoBg && _vm.isWebRowBg
                ? _c("row-video-bg", { attrs: { row: _vm.row } })
                : _vm._e(),
              _vm._v(" "),
              _vm.isSettingCarouselRowAndHorizontalFullScreen
                ? [
                    _vm.manageMode
                      ? _c("alert-component", [
                          _vm._v(" 该栏目通栏暂不支持轮播效果，建议更换 "),
                        ])
                      : _vm._e(),
                  ]
                : _vm.isCarouselRow
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "jz_web_row--carousel_wrap",
                        style: _vm.carouselWrapStyle,
                      },
                      [
                        _vm._l(
                          _vm.carouselList,
                          function (carouselItem, carouselIndex) {
                            return [
                              _c(
                                "div",
                                {
                                  key:
                                    _vm.row.id +
                                    "" +
                                    carouselIndex +
                                    carouselItem.carouselIndex,
                                  staticClass: "jz_web_row--carousel_item",
                                  class: _vm.carouselItemClass(
                                    carouselItem,
                                    carouselIndex
                                  ),
                                  style: _vm.carouselItemStyle(
                                    carouselIndex,
                                    carouselItem.carouselIndex
                                  ),
                                  attrs: {
                                    "data-i": carouselItem.carouselIndex,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.showCarouselItem(carouselIndex),
                                          expression:
                                            "showCarouselItem(carouselIndex)",
                                        },
                                      ],
                                      staticClass: "jz_web_row__content",
                                      class: _vm.contentClass,
                                      style: _vm.rowContentStyle,
                                      attrs: {
                                        "data-carousel-index":
                                          _vm.setCarouselRowContentStyle(
                                            carouselItem.carouselIndex
                                          ),
                                      },
                                    },
                                    [
                                      _vm._l(carouselItem.cols, function (col) {
                                        return _c("jz-web-col", {
                                          key:
                                            col.index +
                                            "" +
                                            carouselItem.carouselIndex +
                                            _vm.row.id,
                                          attrs: {
                                            col: col,
                                            "carousel-cols":
                                              _vm.realCarouselList[
                                                carouselItem.carouselIndex
                                              ]?.cols,
                                            row: _vm.row,
                                            rowid: _vm.row.id,
                                            "clone-module-id":
                                              carouselIndex === 0 ||
                                              carouselIndex ===
                                                _vm.carouselList.length - 1
                                                ? "0"
                                                : "",
                                            "carousel-index":
                                              carouselItem.carouselIndex,
                                            "root-row-id": _vm.rootRowId,
                                          },
                                        })
                                      }),
                                      _vm._v(" "),
                                      _vm.manageMode
                                        ? _c("features", {
                                            attrs: {
                                              inner: "",
                                              feature: _vm.editingPaddingResize,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.carouselStyleType === 2
                      ? [
                          _c("a", {
                            staticClass: "arrow carousel_arrow prev",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.prev.apply(null, arguments)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("a", {
                            staticClass: "arrow carousel_arrow next",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.next.apply(null, arguments)
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    (_vm.carouselStyleType === 1 ||
                      _vm.carouselStyleType === 0) &&
                    _vm.realCarouselList.length
                      ? _c(
                          "ul",
                          {
                            class: {
                              short_line_list: _vm.carouselStyleType === 0,
                              dot_list: _vm.carouselStyleType === 1,
                            },
                          },
                          _vm._l(
                            _vm.realCarouselList,
                            function (photoItem, photoIndex) {
                              return _c("li", {
                                key: photoItem.p + "" + photoIndex + _vm.row.id,
                                staticClass:
                                  "select_photo carousel_select_photo",
                                class: _vm.carouselDotClass(photoIndex),
                                on: {
                                  click: function ($event) {
                                    return _vm.selectPhoto(photoIndex)
                                  },
                                },
                              })
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                : _c(
                    "div",
                    {
                      staticClass: "jz_web_row__content",
                      class: _vm.contentClass,
                      style: _vm.rowContentStyle,
                    },
                    [
                      _vm.isWebContentRow
                        ? _c(
                            "div",
                            { staticClass: "jz_web_col__content" },
                            _vm._l(_vm.row.cols, function (col) {
                              return _c("jz-web-col", {
                                key: col.index,
                                attrs: {
                                  col: col,
                                  row: _vm.row,
                                  rowid: _vm.row.id,
                                  "root-row-id": _vm.rootRowId,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._l(_vm.row.cols, function (col) {
                            return _c("jz-web-col", {
                              key: col.index,
                              attrs: {
                                col: col,
                                row: _vm.row,
                                rowid: _vm.row.id,
                                "clone-module-id": _vm.cloneModuleId,
                                "root-row-id": _vm.rootRowId,
                              },
                            })
                          }),
                      _vm._v(" "),
                      _vm.manageMode
                        ? _c("features", {
                            attrs: {
                              inner: "",
                              feature: _vm.editingPaddingResize,
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
              _vm._v(" "),
              _vm.manageMode
                ? _c("features", {
                    attrs: { inner: "", features: _vm.innerFeatures },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }