import { _voidVm } from '@/site/shared/vueEventBus/index.js';

/**
 * @function name _carouselPhotosObserve
 * @description 轮播多图 监听回调
 * @private
 * @author mikey.chuhui
 * @date 2020-08-07
 * @param val {*}
 */

const _lazyFontObserve = function (val) {
    return new Promise((reslove) => {
        if (val && val.target) {
            //拿到相交模块得id
            const target = $(val.target);
            const moduleId = getProjectId(target);
            if (val.isIntersecting && moduleId) {
                // 进入视图
                initFontFace(moduleId);
                reslove({ val });
            }
        }
    });
};

const initFontFace = (moduleId) => {
    _voidVm.$emit(`fontCss${moduleId}`);
};

/**
 * @function name setImgAttribute
 * @description 设置图片属性
 * @author mikey.chuhui
 * @date 2020-07-24
 * @param el {Object} dom对象
 * @param src {String}
 */

export const addFontFaceToHead = (customFontFaceFamily, substring, resUrl) => {
    if (!customFontFaceFamily || !substring) return;
    const fontInfo = Site.analysisCustomFontFaceFamily(customFontFaceFamily);
    let resSrc = '';
    // resUrl是在初始化的时候调用
    if (resUrl) {
        // 这里特殊处理，资源文件缺失会返回no-pic的图片-_-
        if (resUrl.includes('no-pic')) return;
        resSrc = resUrl;
    } else {
        substring = Fai.unique(substring);
        resSrc = `/font.jsp?cmd=getSubStringFont&substring=${encodeURIComponent(substring)}&id=${
            fontInfo.id
        }&_TOKEN=${$('#_TOKEN').attr('value')}`;
    }
    const fontFaceString = `
        <style type="text/css" id="${customFontFaceFamily}" subString="${substring}">
            @font-face {
                font-family: "${customFontFaceFamily}";
                src: url("${resSrc}") format("truetype");
                font-weight: normal;
                font-style: normal;
            }
        </style>
    `;
    $('head').append(fontFaceString);

    // 这里有个很坑爹的BUG，您要preload font face文件，
    // 单单设置font-family还不行，还需要设置文本，
    // 没有文本的空元素设置font-family是不会preload字体文件的，
    // 元素的属性也不能设置为none，所以在这里设置了一个元素来preload字体文件。
    if ($('#tmpPreloadFontFace').length === 0) {
        const tempFontDiv = $('<div id="tmpPreloadFontFace"></div>').css({
            height: 0,
            width: 0,
            overflow: 'hidden',
        });
        $('body').append(tempFontDiv);
    }
    $('#tmpPreloadFontFace').css('font-family', `${customFontFaceFamily}`).text(substring);
};

/**
 * --------------------------------------------------------------------------------------
 * Constants 交叉观察器的配置数据 这里只放交叉观察器相关的内部函数跟数据
 * --------------------------------------------------------------------------------------
 */
export const LAZY_FONT_DATA = {
    // 懒加载监听器
    id: 6, // 懒加载的标志id 必须
    observerClass: '.cssLazyFont', // 需要监听的元素必须
    observerName: 'observer6', // 这里是为了方便拿这个监听器的名字 基类里给监听器的名字就是根据监听器的标志id来的
    isUnobserve: true, // 执行完回调是否解除监听
    runCallBack: _lazyFontObserve, // 执行回调必须返回一个promise 监听器的执行函数 执行结束后 finishCallBack
    // finishCallBack: _imgFnish,
};

/**
 * @function name - getProjectId
 * @description 获取模块id
 * @private
 * @author mikey.chuhui
 * @date 2020-08-10
 * @param elem {Object} jq Dom对象
 * @example
 * getProjectId($("#module89"))
 * // => 89
 * @returns {Number}
 */

const getProjectId = function (elem) {
    return parseInt(elem.attr('projectid')) || parseInt(elem.attr('id'));
};
