export async function openSearchPopup(searchData) {
    const SiteSearchPopup = await import(/* webpackChunkName: "siteSearchPopup" */ './index.vue');
    const id = 'shopping_cart_popup_popup';
    const cartDiv = document.createElement('div');
    cartDiv.id = id;
    document.body.appendChild(cartDiv);
    const vm = new Vue({
        store: window._store,
        render(h) {
            return h(SiteSearchPopup.default, {
                props: {
                    searchData,
                },
                on: {
                    close: () => {
                        document.body.removeChild(this.$el);
                        vm.$destroy();
                    },
                },
            });
        },
    }).$mount(`#${id}`);
}
