var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentLan
    ? _c(
        "div",
        {
          staticClass: "s_multi_language_container",
          class: "s_multi_language_" + _vm.currentLan.lanCode,
        },
        [
          _c(_vm.multiLanguageStyleComponent, {
            tag: "component",
            attrs: {
              "is-mobi": _vm.isMobi,
              "mobi-icon-color": _vm.mobiIconColor,
              "lan-list": _vm.list,
              "current-lan": _vm.currentLan,
              "icon-visible": _vm.shouldRenderIcon,
              "text-visible": _vm.shouldRenderText,
              "area-type": _vm.renderArea,
              "create-font-style": _vm.createFontStyle,
              "text-style": _vm.textStyle,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }