var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.pattern.h
    ? _c(
        "div",
        { staticClass: "s_member_login", attrs: { id: "sectionMemberLogin" } },
        [
          _c("div", { staticClass: "s_member_login_head" }, [
            _c("i", {
              staticClass:
                "faisco-icons-S000040 jz_theme_font_color s_member_login_icon",
            }),
            _vm._v(" "),
            _c("div", { staticClass: "s_member_login_control" }, [
              _c("ul", { staticClass: "s_member_login_list" }, [
                _c("li", { staticClass: "s_member_login_item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "s_member_login_name",
                      attrs: { href: _vm.mCenterUrl, title: _vm.name },
                    },
                    [_vm._v(_vm._s(_vm.name))]
                  ),
                  _vm._v(" "),
                  _vm.memberLevelAuthInfo.allow
                    ? _c("i", {
                        staticClass: "s_member_login_level",
                        class: _vm.className,
                        style: _vm.iconStyle,
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "s_member_login_item J_memberLoginLogout",
                    on: { click: _vm.logoutClick },
                  },
                  [_vm._v(_vm._s(_vm.LS.topBarLogout))]
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }