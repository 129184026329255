import { throttle, type } from '@jz/utils';
import { updateFullScreenFixTopStyle } from '@/site/shared/fixTopStyle/index.js';
import { TableResponsive } from '@jz/biz-shared';
import { getCarouselRowsEffect } from '@shared/visitor/carouselRowsEffects/index.js';
import { _voidVm as eventBus } from '@/site/shared/vueEventBus/index.js';

export const pureFullScreen = () => {
    console.trace();
};

export const isFullScreen = () => {
    let state = window._store.state;
    return state.fullScreen && (state.device == 'pc' || state.responsiveInfo.pattern.rollingScreen.isMobiSync);
};

/**
 * 初始化全屏通栏
 */
export const initFullScreen = () => {
    if (limitFullScreen()) {
        return;
    }

    if (window._store.state.fullScreen) {
        initPureFullScreen();
        window.dispatchEvent(new window.CustomEvent('pureFullScreenInit', { detail: 'pc' }));

        //打开全屏通栏设置面板
        if (window._store.state.manageMode) {
            window._store.commit('manage/funcPanel/funcPanelChange', {
                type: 'skin',
                second: 'SkinFullScreenCompon',
                active: true,
                index: '1',
            });
        }
    }
};

class ScrollFullScreen {
    constructor(store) {
        let { state } = store;
        let info = state.row.rollingScreenRowInfo;
        this.info = info;
        this.isAxisY = state.fullScreen == 1;
        this.isPreview = Fai.getUrlParam(location.search, '_preview');

        this.rollingScreen = state.responsiveInfo.pattern.rollingScreen;

        this.hasBannerPage = this.hasBanner();
        this.pages = this.getPaginationPages();
        this.init();
    }
    init() {
        this.initPagination();
        this.addPcEvent();
        this.addMobiEvent();
    }
    update() {
        this.pages = this.getPaginationPages();
        this.hasBannerPage = this.hasBanner();
        this.goCurrentPage(this.info.curIndex);
    }
    delRow(rowId) {
        let $contentRowLines = this.getContentRowLines();
        let activeIndex = this.info.curIndex;
        if (this.hasBanner()) {
            activeIndex -= 1;
        }
        let $activeRowLine = $contentRowLines[activeIndex];
        let $row = $activeRowLine.querySelector(`#row${rowId}`);

        if ($row) {
            this.goCurrentPage(this.info.curIndex - 1);
        }
        this.update();
    }

    initPagination() {
        this.commitVuexData({
            instance: this,
        });
    }
    isPC() {
        return this.info.device === 'pc';
    }

    addPcEvent() {
        const $root = document.getElementById('jzWebContainer');
        if (!Fai.isMozilla()) {
            bizShared.on($root, 'wheel', this.scrollMouse.bind(this));
        } else {
            const wheelEvent = !this.isFireFoxNotSupportWheel() ? 'wheel' : 'DOMMouseScroll';
            bizShared.on($root, wheelEvent, this.scrollMouse.bind(this));
        }
        bizShared.on(document, 'keydown', this.keyDown.bind(this));
    }
    isFireFoxNotSupportWheel() {
        return jzUtils.browser.isFirefox() && !('onwheel' in document);
    }
    addMobiEvent() {
        // 移动端滑动事件
        bizShared.on(document, 'touchstart', this.touchStart.bind(this));
        bizShared.on(document, 'touchend touchcancel', this.touchEnd.bind(this));
    }
    touchStart(e) {
        let manageMode = window._store.state.manageMode;
        if (!(manageMode || this.isPreview)) {
            e.stopPropagation();
        }
        this.startY = e.touches ? e.touches[0].pageY : e.pageY;
        this.startX = e.touches ? e.touches[0].pageX : e.pageX;
        if (!(manageMode || this.isPreview) && jzUtils.browser.isAndroid()) {
            bizShared.off(document, 'touchmove', this.touchMoveHandler.bind(this));
            bizShared.on(document, 'touchmove', this.touchMoveHandler.bind(this));
        }
    }
    touchMoveHandler(e) {
        // 阻止浏览器左滑返回上一页

        let endY = e.targetTouches[0].pageY,
            disY = endY - this.startY,
            endX = e.targetTouches[0].pageX,
            disX = endX - this.startX;
        if (Math.abs(disX) > Math.abs(disY)) {
            e.preventDefault();
        }
    }

    touchEnd(e) {
        let manageMode = window._store.state.manageMode;
        if (!(manageMode || this.isPreview)) {
            e.stopPropagation();
        }
        if (jzUtils.browser.isPc() && !jzUtils.browser.isIpad()) return;
        if (manageMode) {
            if (window._store.state.manage.material.materialFactory.visible) return;
            if (window._store.state.manage.row.rowEditingActive) return;
        } else {
            if ($('.shopping_cart_popup_container').length) return;
        }
        const isAxisY = this.isAxisY;
        let endY = e.changedTouches ? e.changedTouches[0].pageY : e.pageY,
            disY = endY - this.startY,
            endX = e.changedTouches ? e.changedTouches[0].pageX : e.pageX,
            disX = endX - this.startX;
        if (this.info.isRunning) {
            // 上一个动画进入未结束
            return;
        }
        let curIndex = this.info.curIndex;

        if (this.selfScroll(disY, e)) {
            return;
        }
        if (isAxisY) {
            if (disY < -20 && Math.abs(disY) >= 20 && Math.abs(disY) > Math.abs(disX)) {
                this.goCurrentPage(curIndex + 1);
            } else if (disY >= -20 && Math.abs(disY) >= 20 && Math.abs(disY) > Math.abs(disX)) {
                this.goCurrentPage(curIndex - 1);
            }
        } else {
            const { moreBanner, isLast } = this.hasMoreBanner(e);
            if (moreBanner) {
                if (!isLast) return;
                setTimeout(() => {
                    if (disX < -20 && Math.abs(disX) >= 20 && Math.abs(disX) > Math.abs(disY)) {
                        this.goCurrentPage(curIndex - 1);
                    } else if (disX >= -20 && Math.abs(disX) >= 20 && Math.abs(disX) > Math.abs(disY)) {
                        this.goCurrentPage(curIndex + 1);
                    }
                }, 800);
            } else {
                if (disX < -20 && Math.abs(disX) >= 20 && Math.abs(disX) > Math.abs(disY)) {
                    this.goCurrentPage(curIndex - 1);
                } else if (disX >= -20 && Math.abs(disX) >= 20 && Math.abs(disX) > Math.abs(disY)) {
                    this.goCurrentPage(curIndex + 1);
                }
            }
        }
    }
    hasMoreBanner(e) {
        // mobi 横屏滚动 横幅滑动最后一页或倒数第二页滑动才触发滚屏
        const $mobiBannerGroup = $('.jz_mobi_banner_group .banner_pic_item_wrap').length
                ? $('.jz_mobi_banner_group .banner_pic_item_wrap')
                : this._manageMode
                ? $('.banner_pic_group .banner_pic_item_wrap')
                : $('.jz_mobi_same_pc_banner .banner_pic_item_wrap'),
            mobiBannerGroupLen = $mobiBannerGroup.length,
            isLast =
                $mobiBannerGroup.eq(mobiBannerGroupLen - 1).hasClass('banner_item_actived') ||
                $mobiBannerGroup.eq(0).hasClass('banner_item_actived');
        return {
            moreBanner:
                ($(e.target).parents().hasClass('banner_pic_item_wrap') ||
                    $(e.target).hasClass('banner_pic_item_wrap') ||
                    $(e.target).find('.banner_pic_item_wrap').length) &&
                $mobiBannerGroup.length > 1,
            isLast,
        };
    }

    scrollMouse(event) {
        const throttleTime = jzUtils.browser.isMac() ? 2000 : 800;
        const curIndex = this.info.curIndex;
        let handler = throttle(() => {
            event.stopPropagation();
            //if (this._checkRunAnimation()) return;
            const deltaData = this.getWheelDelta(event);
            if (this.selfScroll(deltaData, event)) return;
            // 处理笔记本惯性滚动，滚动距离大于110时，滚动到下一页
            const isFireFoxNotSupportWheel = this.isFireFoxNotSupportWheel();
            if (isFireFoxNotSupportWheel || Math.abs(deltaData) > 110) {
                this.goCurrentPage(curIndex + (deltaData < 0 ? 1 : -1));
            }
        }, throttleTime);
        handler();
    }
    isInContainerScroll(deltaData, event, className) {
        const path = event.path || (event.composedPath && event.composedPath());
        if (path && path.length) {
            const hoverContentScrollEl = path.find((item) => {
                return item.classList && item.classList.contains(className);
            });
            if (hoverContentScrollEl) {
                const hoverContentScrollHeight = hoverContentScrollEl.scrollHeight;
                const hoverContentClientHeight = hoverContentScrollEl.clientHeight;
                const hoverContentScrollTop = hoverContentScrollEl.scrollTop;
                const hasScroll = hoverContentScrollHeight - hoverContentClientHeight > 0;
                if (hasScroll) {
                    const isUp = deltaData > 0;
                    const isDown = deltaData < 0;
                    if (isUp) {
                        if (hoverContentScrollTop > 0) {
                            return true;
                        }
                    }
                    if (isDown) {
                        if (hoverContentScrollHeight - hoverContentClientHeight - hoverContentScrollTop > 0) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }
    selfScroll(deltaData, event) {
        try {
            if (this.isInContainerScroll(deltaData, event, 'hover_col_content')) {
                return true;
            }
            let $contentRowLines = this.getContentRowLines();
            const curIndex = this.info.curIndex;
            let curIsBanner = this.hasBanner() && curIndex == 0;
            if (curIsBanner) {
                //TODO-coyife
                return;
            }
            let curRowIndex = this.hasBanner() ? curIndex - 1 : curIndex;

            let $rowFull = $contentRowLines[curRowIndex];
            //有底部的情况很复杂 且当前是最后一个通栏
            if (this.hasFooter() && this.isFooterPageActive) {
                let $footer = document.getElementById('gridFooter');
                if (this.rollingScreen.bottomType == 1) {
                    //底部单独一行
                    //deltaData < 0 , 往下滚, deltaData > 0 往上滚
                    if ($footer.scrollTop > 0 && deltaData > 0) {
                        return true;
                    }
                    return false;
                } else if (this.rollingScreen.bottomType == 2) {
                    //底部和最后一个通栏同一行
                    if (event && event.currentTarget) {
                        let lastContentPage = $contentRowLines[$contentRowLines.length - 1];
                        if ($(lastContentPage).find(event.currentTarget).length) {
                            //最后一个通栏内滚动
                            $rowFull = lastContentPage;
                        } else {
                            //deltaData < 0 , 往下滚, deltaData > 0 往上滚
                            if ($footer.scrollTop > 0 && deltaData > 0) {
                                return true;
                            }
                            return false;
                        }
                    }
                }
            }

            let $row = getElemBySelector('.jz_web_row', $rowFull)[0];
            let $rowContent = getElemBySelector('.jz_web_row__content--full .jz_web_col__content', $row)[0];
            if (!$rowContent && $rowFull) {
                // 检查是否轮播通栏
                const rowId = $rowFull.getAttribute('id').replace('rowline', '');
                const carouselRowsEffect = getCarouselRowsEffect(rowId);
                if (!carouselRowsEffect) {
                    return true;
                }
                const $carouselRowContent = $($rowFull)
                    .find('.jz_web_row--carousel_item')
                    .eq(carouselRowsEffect.carouselIdx);
                $rowContent = $carouselRowContent.find('.web_col_content')[0];
                $row = $carouselRowContent[0];
            }
            if (!$rowContent || !$rowFull) {
                return true;
            }
            //deltaData < 0 , 往下滚, deltaData > 0 往上滚
            if (deltaData < 0) {
                if ($rowContent.offsetHeight - $rowFull.offsetHeight - $row.scrollTop <= 1) {
                    return false;
                }
            } else {
                if ($row.scrollTop <= 0) {
                    return false;
                }
            }

            return true;
        } catch (e) {
            console.error(e);
            console.log(this.info.curIndex);
        }
    }

    /**
     * @description 获取鼠标滚轮事件信息 兼容fireFox 的滚轮信息 正负值跟其他浏览器相反
     * fireFox 向前滚属性值是3的倍数 其他浏览器这是-120的倍数
     * @author mikey.chuhui
     * @date 2020-07-24
     * @param e {Object} 事件对象
     * @returns {Number}
     * @memberof PureFullScreen
     */

    getWheelDelta(e) {
        if (!e) return;
        return e.wheelDelta ? e.wheelDelta : -e.detail;
    }
    keyDown(e) {
        const keyCode = e.keyCode;
        const isAxisY = this.isAxisY;
        let curIndex = this.info.curIndex;
        if (isAxisY) {
            if (keyCode == 38) {
                // UP_ARROW
                this.goCurrentPage(curIndex - 1);
            } else if (keyCode == 40) {
                // DOWN_ARROW
                this.goCurrentPage(curIndex + 1);
            }
        } else {
            if (keyCode == 37) {
                // LEFT_ARROW
                this.goCurrentPage(curIndex - 1);
            } else if (keyCode == 39) {
                // RIGHT_ARROW
                this.goCurrentPage(curIndex + 1);
            }
        }
    }
    _getRowIds() {
        const { rowIds, footerRowIds } = window._store.state.responsiveInfo;
        return [...rowIds, ...footerRowIds];
    }

    goCurrentPage(idx, noLimit = false) {
        let isRunning = this.info.isRunning;
        //检查是否能动画，页面切换中不能执行
        if (!noLimit && isRunning) {
            return;
        }
        //编辑中的全屏通栏禁止滚动
        if (window._store.state.row.rowEditing) {
            return;
        }
        let curIndex = this.info.curIndex;
        let targetIndex = this.checkIndex(idx);
        this.isLastScreen = this.checkIsLastScreen(targetIndex);

        const rowIds = this._getRowIds();
        const rowId = rowIds[this.hasBanner() ? idx - 1 : idx];

        let scrollDir = this.getScrollDir(targetIndex, curIndex);
        let animateClass = getAnimateClass(this.rollingScreen.animate, scrollDir);
        this.commitVuexData({
            curIndex: targetIndex,
            animateClass,
            isFooterPageActive: this.isFooterPageActive,
            isLastScreen: this.isLastScreen,
            rowId,
        });

        let fixedTop = window._store.state.responsiveInfo.pattern.rollingScreen.fixedTop;
        fixedTop && updateFullScreenFixTopStyle(targetIndex);
        setTimeout(() => {
            TableResponsive.init({
                $tables: $('.module_content_detail table'),
                parentClassName: '.module_content_detail',
            });
        });
        eventBus.$emit('fullScreenAfterGoPage', 'pc');
    }
    goCurrentRow(rowId, update = false) {
        update && this.update();
        let allRowIds = this._getRowIds();
        let index = allRowIds.indexOf(rowId);
        if (index > -1) {
            this.goCurrentPage(this.hasBanner() ? index + 1 : index);
        }
    }
    checkIndex(idx) {
        let maxIndex = this.getMaxIndex();

        //纵向，且底部单独一行
        if (this.isAxisY && this.rollingScreen.bottomType == 1 && idx >= maxIndex) {
            this.isFooterPageActive = true;
        } else {
            this.isFooterPageActive = false;
        }
        idx = idx < 0 ? 0 : idx;
        idx = idx > maxIndex ? maxIndex : idx;

        return idx;
    }
    getCurIndex() {
        return this.info.curIndex;
    }

    checkIsLastScreen(index) {
        if (index !== this.getMaxIndex()) {
            return false;
        }

        return true;
    }

    commitVuexData(data = {}) {
        window._store.commit('row/setRollingScreenRowInfo', data);
        window._store.commit('backToTop/setScrollYValue', data.curIndex);
    }
    getMaxIndex() {
        let pages = this.getContentRowLines().length - 1;
        if (this.hasBanner()) {
            pages++;
        }
        if (this.hasFooter() && this.rollingScreen.bottomType == 1) {
            pages++;
        }
        return pages;
    }

    getPaginationPages() {
        let pages = this.getContentRowLines().length;
        if (this.hasBanner()) {
            pages++;
        }
        return pages;
    }

    hasBanner() {
        const _banner = document.getElementById('jz_banner');
        const _mobiHideBanner = getElemBySelector('.jz_visitor_banner_mobi_hide')[0];

        return this.isPC() ? !!_banner : !!_banner && !_mobiHideBanner;
    }
    hasFooter() {
        return !!document.getElementById('gridFooter');
    }

    // 获取底部相关信息
    getFooterInfo() {
        const $footer = document.getElementById('gridFooter');
        return {
            $footer,
            height: $footer ? $footer.offsetHeight : 0,
            hasFooter: !!$footer,
        };
    }
    getContentRowLines() {
        return getElemBySelector('.jz_web_row_line--full');
    }

    getScrollDir(targetIndex, curIndex) {
        if (targetIndex > curIndex) {
            // 鼠标向前滚动 页面向下滚动 向上过渡
            return this.isAxisY ? 'Bottom' : 'Left';
        } else {
            return this.isAxisY ? 'Top' : 'Right';
        }
    }
}

function initPureFullScreen() {
    JZ.pureFullScreen = new ScrollFullScreen(window._store);
}

/**
 * @description
 * @author coyife
 * @date 2022-05-05
 * @param {*} animate
 * @param {*} direction
 * @returns {Object}  as {in: 'jz_page_moveLeftIn', out: 'jz_page_moveLeftOut'} or {in: 'jz_page_rotateRoomBottomIn', out: 'jz_page_rotateRoomBottomOut'}
 */
function getAnimateClass(animate, direction) {
    // let animateNameMap = {
    //     1: [`move${direction}`, `move${direction}`],
    //     2: [`move${direction}In`, `${direction}OutEasing`],
    //     3: [`rotateRoom${direction}In`, `rotateRoom${direction}Out`],
    // }
    let animateNameMap = {
        1: `move${direction}`,
        2: `move${direction}`,
        3: `rotateRoom${direction}`,
    };

    return animateNameMap[animate];
}

function limitFullScreen() {
    // ipad下不需要全屏效果，应该后端判断更为准确
    if (!window._store.state.manageMode && jzUtils.browser.isIpad()) {
        window._store.commit('changeFullScreen', 0);
        $('html')
            .removeClass('jz_full_screen_scrolling full_screen_col full_screen_row sync_full_screen')
            .addClass('jz_full_screen_scrolling__ipad');
        $(window).trigger('resize');
        return true;
    }
}

function getElemBySelector(className, parent = document) {
    return parent.querySelectorAll(className);
}

/*
管理态方法
*/

export function gotoFullScreenIndex(index, noLimit = false) {
    if (!JZ.pureFullScreen) {
        return;
    }
    let indexType = type(index);
    if (indexType === 'string') {
        let curIndex = JZ.pureFullScreen.getCurIndex();
        switch (index) {
            case '+1':
                JZ.pureFullScreen.goCurrentPage(curIndex + 1, noLimit);
                break;
            case '-1':
                JZ.pureFullScreen.goCurrentPage(curIndex - 1, noLimit);
                break;
            default:
                console.warn('参数有误');
        }
        return;
    }
    if (indexType === 'number') {
        JZ.pureFullScreen.goCurrentPage(index);
        return;
    }
}
export function gotoFullScreenRowId(rowId, refresh = false) {
    if (rowId && JZ.pureFullScreen) {
        JZ.pureFullScreen.goCurrentRow(rowId, refresh);
    }
}

export function updateFullScreen() {
    if (JZ.pureFullScreen) {
        JZ.pureFullScreen.update();
    }
}

export function delFullScreenRow(rowId) {
    if (JZ.pureFullScreen) {
        JZ.pureFullScreen.delRow(rowId);
    }
}
