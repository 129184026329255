export default {
    namespaced: true,
    state: () => ({
        isOpenFixedHeight: false,
        isOpenFixedTop: false,
        // 固定顶部
        scrollToTop: true,
        headerStyleStatus: {
            status: false,
            headerHeight: 0,
        },
        isHoverHeader: false,
        fixedHeaderHeight: 0, //fixed 顶部or导航栏高度
        isRightPosition: false,
    }),
    mutations: {
        setIsOpenFixedHeight(state, value) {
            state.isOpenFixedHeight = value;
        },
        setIsOpenFixedTop(state, value) {
            state.isOpenFixedTop = value;
        },
        changeHeaderStyleStatus(state, { status, headerHeight }) {
            (status !== null || status !== undefined) && (state.headerStyleStatus['status'] = status);
            (headerHeight !== null || headerHeight !== undefined) &&
                (state.headerStyleStatus['headerHeight'] = headerHeight);
        },
        changeFixedHeaderHeight(state, headerHeight) {
            state.fixedHeaderHeight = headerHeight;
        },

        setScrollToTop(state, flag) {
            state.scrollToTop = flag;
        },
        setIsHoverHeader(state, value) {
            state.isHoverHeader = value;
        },
        setRightPosition(state, value) {
            state.isRightPosition = value;
        },
    },
    getters: {
        isHoverHeaderAndOpenHoverEffect(state, _, rootState) {
            const fullScreen = rootState.fullScreen;
            const responsiveInfo = rootState.responsiveInfo;
            const currentPattern =
                fullScreen === 0 ? responsiveInfo.pattern.header : responsiveInfo.pattern.rollingScreen;

            return currentPattern.se && state.isHoverHeader;
        },
        currentIsFixedTop(state, _, rootState, rootGetters) {
            const isFullScreen = rootGetters.isFullScreen;
            const { rollingScreen, header } = rootState.responsiveInfo.pattern;
            const headerData = (isFullScreen ? rollingScreen : header) || {};
            const isOpenFixedTop = headerData.fixedTop == 1 && rollingScreen.type === 0;
            const headerShouldShowFixedMode = state.isRightPosition || state.isHoverHeader === true;

            return (isOpenFixedTop || (isFullScreen && rollingScreen.fixedTop === 1)) && headerShouldShowFixedMode;
        },
    },
};
