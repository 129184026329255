
import { mapGetters, mapState } from 'vuex';

import jzModule from '@/components/modules/jzModule.vue';
import SectionWebsiteTitle from './system/sectionWebsiteTitle.vue';
import SectionMultiLanguage from './system/multiLanguage/index.vue';
import SectionMemberLogin from './system/sectionMemberLogin/index.vue';
import SectionNav from './system/nav/index.vue';
import SectionFooter from './system/sectionFooter.vue';
import sectionTopShoppingCart from './system/TopShoppingCart/index.vue';


export default {
    name: 'Project', //moduleIndex 模块在当前通栏的位置
    components: {
        SectionWebsiteTitle,
        SectionMultiLanguage,
        SectionMemberLogin,
        SectionNav,
        SectionFooter,
        sectionTopShoppingCart,
    },
    inject: {
        isSysRow: {
            default: false,
        },
        rowNewMobiResponsiveConversion: {
            default: false,
        },
        rowNewResponsiveConversion: {
            default: false,
        },
    },
    props: ['rowid', 'id', 'moduleIndex', 'cloneModuleId'],
    render(h) {
        const projectComponent = (props = {}) => {
            return h(this.curCompon, {
                //动态组件
                ref: 'jzProject',
                props: {
                    id: this.id,
                    rowid: this.rowid,
                    projectid: this.moduleId,
                    ['module-index']: this.moduleIndex,
                },
                domProps: {
                    projectid: this.moduleId,
                    rowid: this.rowid,
                    ['module-index']: this.moduleIndex,
                },
                style: this.wrapMarginStyle,
                directives: [
                    {
                        name: 'contextmenu',
                        value: this.contextmenus,
                    },
                ],
                class: {
                    jz_project: true,
                    [`jz_project_${this.id}`]: true,
                    jz_project__edit: this.manageMode && this.moduleEditorActiveId === this.moduleId,
                    [props.class]: this.manageMode,
                },
            });
        };
        return this.manageMode ? (
            <DndDraggable
                renderless={true}
                {...{
                    scopedSlots: {
                        //slot 传参
                        default: projectComponent,
                    },
                }}
            ></DndDraggable>
        ) : (
            projectComponent()
        );
    },
    computed: {
        ...mapState('manage/editPanel', ['moduleEditorActiveId']),
        ...mapState([
            'device',
            'manageMode',
            'newResponsiveConversion',
            'newMobiResponsiveConversion',
            'templateNewResponsiveConversion',
            'templateNewMobiResponsiveConversion',
        ]),
        ...mapState('popupZone', {
            popupZoneRowId: (state) => state.rowId,
        }),
        ...mapGetters(['info']),
        ...mapGetters('popupZone', ['isVisitMode']),
        isSection() {
            return this.id >= 150 && this.id < 200;
        },
        curCompon() {
            if (this.isSection) {
                switch (this.id) {
                    //导航
                    case 150:
                        return 'sectionNav';
                    // 会员登录部件
                    case 151:
                        return 'sectionMemberLogin';
                    // 底部部件
                    case 152:
                        return 'sectionFooter';
                    // 网站标题
                    case 153:
                        return 'sectionWebsiteTitle';
                    // 多语言组件
                    case 154:
                        if (!this.$store.state.sections.multiLanguage.showLan) {
                            return;
                        }
                        return 'sectionMultiLanguage';
                    // 顶部购物车
                    case 155:
                        if (!this.$store.state.responsiveInfo.pattern.topShoppingCart.open) {
                            return;
                        }
                        return 'sectionTopShoppingCart';
                }
            }
            return jzModule;
        },
        moduleId() {
            return this.cloneModuleId !== undefined ? parseInt(this.id + this.cloneModuleId) : this.id;
        },

        isMobi() {
            return this.device == 'mobi';
        },

        modules() {
            return this.$store.state.modules;
        },
        margin() {
            switch (this.curCompon) {
                case 'sectionNav':
                    return this.info.pattern.nav;
                case 'sectionMultiLanguage':
                    return this.info.pattern.multiLanguage;
                case 'sectionMemberLogin':
                    return this.info.pattern.memberLogin;
                case 'sectionFooter':
                    return {}; //不注册
                case 'sectionWebsiteTitle':
                    return this.info.pattern.title;
                case 'sectionTopShoppingCart':
                    return this.info.pattern.topShoppingCart;
                case jzModule:
                    return this.modules['module' + this.id].pattern[this.device];
                default:
                    //可能是开过多语言后又关闭的站点。
                    return {};
            }
        },
        isNewMobiResponsiveMargin() {
            return (
                this.newMobiResponsiveConversion ||
                (this.isSysRow && this.templateNewMobiResponsiveConversion) ||
                this.rowNewMobiResponsiveConversion
            );
        },
        isNewPcResponsiveMargin() {
            return (
                this.newResponsiveConversion ||
                (this.isSysRow && this.templateNewResponsiveConversion) ||
                this.rowNewResponsiveConversion
            );
        },
        wrapMarginStyle() {
            //这里只设置pc的margin，因为控件在mobi状态下不包含margin，
            //模块margin交由模块内部自身实现
            if (
                !this.curCompon === jzModule ||
                (this.curCompon === jzModule &&
                    (!this.modules[`module${this.id}`] || this.modules[`module${this.id}`].commProp.ls.st !== 0))
            ) {
                return {};
            }
            var margin = this.margin;
            let unit = '%',
                _outerWidth = 1,
                mobiClientWidth = 1;

            if (this.popupZoneRowId !== this.rowid) {
                if (this.isMobi) {
                    if (this.isNewMobiResponsiveMargin) {
                        // 新得间距换算逻辑
                        unit = 'vw';
                        mobiClientWidth = 375;
                        if (this.$isServer) {
                            _outerWidth = 375;
                        } else {
                            _outerWidth = Math.min(window.innerWidth, window.outerWidth);
                        }
                    }
                } else {
                    if (this.isNewPcResponsiveMargin) {
                        unit = 'vw';
                    }
                }
            }

            let _marginTop = `${((margin.mt * mobiClientWidth) / _outerWidth) * 100}${unit}`,
                _marginRight = `${margin.mr * 100}%`,
                _marginBottom = `${((margin.mb * mobiClientWidth) / _outerWidth) * 100}${unit}`,
                _marginLeft = `${margin.ml * 100}%`;
            return {
                marginTop: _marginTop,
                marginRight: _marginRight,
                marginBottom: _marginBottom,
                marginLeft: _marginLeft,
            };
        },
    },
    methods: {
        contextmenus(...args) {
            return moduleContextmenus.apply(this, args);
        },
    },
};
