import { slideshowView } from '@shared/visitor/slideshow/index.js';
import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { MemberFileUtil, memberFileUpload } from '@/site/utils.js';
import {
    setMemberMsg,
    setLogoutMember,
    getSubmitList,
    changeReadStatus,
    changeSubmitStatusPost,
} from '@/api/member/index.js';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
import FormDetailEdit from './formDetailEdit.vue';
import { encodeHtmlStr } from '@/components/utils';

const commonObj = {
    flag: true,
};

/**
 * 初始化会员头像
 * @param {Object} foo
 * @param {Object} foo.headPic 头像的数据
 * @param {Number} foo.moduleId 模块id
 * @param {Boolean} foo.isDefaultImg 是否是默认头像
 */
export function initMemberHeadImg({ headPic = {}, moduleId = 0, isDefaultImg = false }) {
    const $memberCenterContentImg = $('#module' + moduleId).find('.J_memberCenterContentImg');

    if (isDefaultImg) {
        // 默认图片给与默认的尺寸
        $memberCenterContentImg.css('width', '100%');
    } else {
        // 自定义图片自定义尺寸
        const { width = 0, left = 0, top = 0, imgW = 0, imgH = 0, path = '' } = headPic;

        const widthPercent = (imgW / width) * 100;
        const heightPercent = (imgH / width) * 100;
        if (widthPercent <= 0 || heightPercent <= 0) {
            $memberCenterContentImg.css('width', '100%');
        } else {
            $memberCenterContentImg.css({
                left: -(left / width) * 100 + '%',
                top: -(top / width) * 100 + '%',
                width: widthPercent + '%',
                height: heightPercent + '%',
            });
        }

        if (path) {
            $memberCenterContentImg.attr('src', path);
        }
    }

    // 后台使用visibility隐藏样式 防止位置发生变化时的闪动
    $memberCenterContentImg.css('visibility', '');
}

/**
 * 修改密码
 * @param {Object} foo
 * @param {Number} foo.moduleId 模块id
 * @param {Number} foo.sessionMid 该会员的session mid
 */
export function confirmPwd({ moduleId = 0, sessionMid = 0 }) {
    const $module = $('#module' + moduleId);
    const $memberCenterOldPwd = $module.find('.J_memberCenterOldPwd');
    const $memberCenterPwd = $module.find('.J_memberCenterPwd');
    const $memberCenterRepwd = $module.find('.J_memberCenterRepwd');

    let oldPwd = '';
    if ($memberCenterOldPwd.length > 0) {
        oldPwd = $memberCenterOldPwd.val();
        if (oldPwd === '') {
            warningMessage(LS.memberProfileOldPwdEmpty, true);
            $memberCenterOldPwd.focus();
            return;
        }
    }

    let pwd = '';
    if ($memberCenterPwd.length > 0) {
        pwd = $memberCenterPwd.val();
    }
    if (pwd === '') {
        warningMessage(LS.memberProfilePwdEmpty, true);
        $memberCenterPwd.focus();
        return;
    }
    if (pwd.length < 4) {
        warningMessage(LS.memberProfilePwdMinLength, true);
        $memberCenterPwd.focus();
        return;
    }

    let repwd = '';
    if ($memberCenterRepwd.length > 0) {
        repwd = $memberCenterRepwd.val();
    }
    if (repwd !== pwd) {
        warningMessage(LS.memberProfilePwdNotMatch, true);
        $memberCenterRepwd.focus();
        return;
    }

    const info = {};
    info.oldPwd = $.md5(oldPwd);
    info.pwd = $.md5(pwd);

    // 发起修改密码请求
    new Promise((resolve, reject) => {
        setMemberMsg({
            id: sessionMid,
            info: $.toJSON(info),
        }).then((data) => {
            data.success ? resolve(data) : reject(data);
        });
    })
        .then(() => {
            successMessage(LS.memberProfileOK, true);
            window.location.reload();
        })
        .catch((data = {}) => {
            if (data.rt == -3) {
                warningMessage(LS.memberProfileOldPwdIncorrectError, true);
            } else if (data.rt == -6) {
                warningMessage(LS.memberSignupRegisterExisted, true);
            } else if (data.rt == -8) {
                warningMessage(LS.emailPlural, true);
            } else if (data.rt == -9) {
                warningMessage(LS.mobilePlural, true);
            } else if (data.mobileErr == true) {
                warningMessage(LS.mobileNumRegular, true);
                $('#mobile').focus();
            } else {
                warningMessage(data.msg ? data.msg : LS.js_systemError, true);
            }
        });
}

/**
 * 修改资料
 * @param {Object} foo
 * @param {Number} foo.moduleId 模块id
 * @param {Number} foo.sessionMid 该会员的session mid
 */
export function confirmProfile({ moduleId = 0, sessionMid = 0 }) {
    const info = {};

    let hasSetMobile = false;
    let valid = true;
    $('#module' + moduleId)
        .find('.J_userEditItem')
        .each((index, item) => {
            const userEditItemName = $(item).attr('id');
            const userEditItemValue = $(item).val();
            const userEditItemMaxLength = $(item).attr('maxlength');
            const name = $(item).data('name');
            const userEditItemRequired = $(item).data('required');
            if (!$(item).attr('disabled')) {
                info[userEditItemName] = userEditItemValue;
            }

            if (userEditItemName == 'mobile' && userEditItemValue.length > 0) {
                if ($(item).attr('disabled') != 'disabled') {
                    // 兼容 +86 xxxx 手机号码的情况
                    const newMobile = userEditItemValue.replace(/\+[\d-]+\s+/, '');
                    if (!Fai.isNationMobile(newMobile)) {
                        warningMessage(LS.mobileNumRegular, true);
                        $(item).focus();
                        valid = false;
                        return false;
                    } else {
                        info['mobileCt'] = $('#mobileCt').val();
                        hasSetMobile = true;
                    }
                } else {
                    info[userEditItemName] = '';
                }
            }
            if (userEditItemName == 'email' && userEditItemValue.length > 0) {
                if (!Fai.isEmail(userEditItemValue)) {
                    warningMessage(LS.memberDialogPleaseSureMail, true);
                    $(item).focus();
                    valid = false;
                    return false;
                }
            }
            if (userEditItemValue.length > userEditItemMaxLength) {
                warningMessage(Fai.format(LS.msgBoardInputMaxLength, name, userEditItemMaxLength), true);
                $(item).focus();
                valid = false;
                return false;
            }
            if (parseInt(userEditItemRequired)) {
                if (userEditItemValue === '') {
                    if ($(item).hasClass('member_file_input')) {
                        if (!userEditItemValue.length) {
                            warningMessage(Fai.format(LS.site_memberSignupUserAddItemIsEmpty2, name), true);
                        }
                    } else if ($(item).is('input')) {
                        warningMessage(Fai.format(LS.msgBoardInputIsEmpty, name), true);
                    } else {
                        warningMessage(Fai.format(LS.site_memberSignupUserAddItemIsEmpty2, name), true);
                    }
                    $(item).focus();
                    valid = false;
                    return false;
                }
            }
        });

    if (!valid) {
        return;
    }

    // 发起修改资料请求
    new Promise((resolve, reject) => {
        setMemberMsg({
            id: sessionMid,
            info: $.toJSON(info),
        }).then((data) => {
            data.success ? resolve(data) : reject(data);
        });
    })
        .then((data = {}) => {
            if (data.changeAcct) {
                $('#module' + moduleId)
                    .find('#acct')
                    .prop('disabled', true);
            }
            if (hasSetMobile) {
                $('#module' + moduleId)
                    .find('#mobileCt')
                    .hide();
            }

            const previewDom = $('#module' + moduleId).find('.member_file_preview_link');
            const downloadDom = $('#module' + moduleId).find('.member_file_download_link');
            if (previewDom.length > 0) {
                previewDom.show();
            }
            if (downloadDom.length > 0) {
                downloadDom.show();
            }

            MemberFileUtil.saveMemberFile();
            successMessage(LS.memberProfileOK, true);
        })
        .catch((data = {}) => {
            if (data.rt == -3) {
                warningMessage(LS.memberProfileOldPwdIncorrectError, true);
            } else if (data.rt == -6) {
                warningMessage(LS.memberSignupRegisterExisted, true);
            } else if (data.rt == -8) {
                warningMessage(LS.emailPlural, true);
            } else if (data.rt == -9) {
                warningMessage(LS.mobilePlural, true);
            } else if (data.mobileErr == true) {
                warningMessage(LS.mobileNumRegular, true);
                $('#mobile').focus();
            } else {
                warningMessage(data.msg ? data.msg : LS.js_systemError, true);
            }
        });
}

/**
 * 关闭内容面板
 * @param {Number} moduleId 模块id
 */
export function closeContentPanel(moduleId = 0) {
    $('#module' + moduleId)
        .find('.J_memberCenterMain')
        .removeClass('m_member_center_main_panel');
}

/**
 * 我的表单 内容区域
 */
export function initFormList(isAll) {
    if (window._store.state.manageMode) {
        $('.m_member_center_form_wrap .member_no_list').show();
        return;
    }
    const isMobi = window._store.getters.isMobi;
    getSubmitList({
        needFormStatus: true,
        pageSite: 10,
        pageNo: isMobi ? -1 : 1,
    })
        .then((pageObj) => {
            if (!pageObj.pageParam) {
                return;
            }
            commonObj.showStatusCol = pageObj.showStatusCol;
            commonObj.showTabForm = pageObj.pageParam.showTabForm;
            //隐藏侧栏我的表单入口
            if (!commonObj.showTabForm) {
                $('.memberMineForms').hide();
            }
            if (pageObj.pageParam.siteFormSubmitList.length == 0 && commonObj.showTabForm) {
                $('.m_member_center_form_wrap .member_no_list').show();
                return;
            } else if (commonObj.showTabForm) {
                renderFormList(pageObj, isAll);
                renderPagination(pageObj);
                if (!commonObj.showStatusCol) {
                    $('#memberCenterForms').addClass('none_status');
                }
            }
            // 绑定查看详情事件
            initFormDetail();
        })
        .catch((e) => {
            Fai.ing('系统错误，请稍候再试');
            console.error(e);
        });
}

// 从 initFormDetail 抽取出来
export function handleClickFormItem(target) {
    if (!commonObj.flag) {
        return;
    }
    commonObj.flag = false;
    var id = $(target).attr('data-id');
    var isShowRemark = $(target).attr('data-remark');
    changeReadStatus({
        id: id,
        callBackData: true,
        haveRead:
            $('#memberCenterForms .member_mine_forms').attr('data-allowremark') == 'true' && isShowRemark == 'true'
                ? true
                : false,
    }).then(async (formItem) => {
        if (!formItem.success) {
            return;
        }
        // 组装渲染表单内容的数据
        var keyObj = formItem.siteFormInfo ? formItem.siteFormInfo : false;
        var valueObj = formItem.submitInfo ? formItem.submitInfo : false;
        var formRenderList = [];
        var payData = {
            openOnlinePay: formItem.siteFormInfo.openOnlinePay,
            payTxt: formItem.siteFormInfo.payTxt,
            payStatus: formItem.submitInfo.payStatus,
            price: formItem.submitInfo.price,
            isAliPay: formItem.submitInfo.isAliPay,
            isFaiPay: formItem.submitInfo.isFaiPay,
            isWXPay: formItem.submitInfo.isWXPay,
            isPayPal: formItem.submitInfo.isPayPal,
            isStripe: formItem.submitInfo.isStripePay,
            isPayssion: formItem.submitInfo.isPayssion,
        };
        if (keyObj && valueObj) {
            var remark = valueObj.remark;
            var keyArr = keyObj.contentList ? keyObj.contentList : [];
            var valueArr = valueObj.submitContentList ? valueObj.submitContentList : [];
            var showFormStatus = keyObj.showFormStatus;
            var formStatusName = valueObj.formStatusName;
            if (keyArr.length && valueArr.length) {
                keyArr.forEach((item) => {
                    let result = valueArr.some((val) => {
                        return item.id == val.id;
                    });
                    let valueObj = valueArr.find((val) => {
                        return item.id == val.id;
                    });
                    if (result || item.type == 5) {
                        // 文本说明特殊处理
                        if (item.type != 5) {
                            if (item.type === 7) {
                                formRenderList.push({
                                    id: item.id,
                                    key: item.name,
                                    type: item.type,
                                    value: valueObj.val,
                                    siteFormFileList:
                                        formItem?.submitInfo?.siteFormFileList?.filter?.((file) =>
                                            valueObj?.val?.includes?.(file.id)
                                        ) ?? [],
                                });
                            } else if (item.type === 8) {
                                formRenderList.push({
                                    id: item.id,
                                    type: item.type,
                                    key: item.name,
                                    value: valueObj.val,
                                    phoneText: valueObj.phoneText,
                                });
                            } else {
                                formRenderList.push({
                                    id: item.id,
                                    type: item.type,
                                    key: item.name,
                                    value: valueObj.val,
                                });
                            }
                        } else {
                            formRenderList.push({
                                id: item.id,
                                type: item.type,
                                key: '主标题',
                                value: item.headline,
                            });
                            formRenderList.push({
                                id: item.id,
                                type: item.type,
                                key: '副标题',
                                value: item.input,
                            });
                        }
                    }
                });
            }
        }
        const idData = {
            submitShowId: formItem.siteFormInfo.submitShowId,
            id: formItem.submitInfo.id,
        };
        // 隐藏表单项目红点
        $(target).find('.view_detail').removeClass('view_detail_active');
        $(target).siblings('.form_item_center').removeClass('form_item_center_active');

        const submitList = await loadSubmitList();

        //循环列表查看是否隐藏侧栏的我的表单的红点
        hideTabRedTip(submitList);

        const canEdit = submitList.find((item) => item.id === parseInt(id))?.openMemberModifySubmit ?? false;
        // 渲染表单详情
        renderFormDetail(
            formRenderList,
            isShowRemark,
            remark,
            formItem.siteFormInfo.name,
            showFormStatus,
            formStatusName,
            payData,
            idData,
            canEdit
        );
    });
}

function initFormDetail() {
    $('.m_member_form_list .form_item_right').click((e) => {
        handleClickFormItem(e.target);
    });
}
function renderFormDetail(
    formRenderList,
    isShowRemark,
    remark,
    formName,
    showFormStatus,
    formStatusName,
    payData,
    idData,
    canEdit
) {
    var formContentHtml = '';
    const imageMap = new Map();
    formRenderList.forEach((item) => {
        // var value =
        //     item.value.split('\n').length > 1
        //         ? item.value.split('\n').join('\n')
        //         : item.value;
        var value = Array.isArray(item.value)
            ? item.value
            : (item.value ?? '').split('\n').length > 1
            ? (item.value ?? '').split('\n').join('\n')
            : item.value ?? '';

        let valueHtml = Fai.encodeHtml(value);
        if (item.type === 8) {
            // 手机号更改显示文本
            valueHtml = item?.phoneText ?? value;
        }

        if (item.siteFormFileList) {
            const previewList = [];
            valueHtml = '<div class="form_item_file_container"> <ul class="form_item_file_list">';
            for (let file of item.siteFormFileList) {
                const { name, thumbUrl, size, id, previewUrl } = file;
                const fileType = bizShared?.getFileType(name);
                const iconClass = `form_item_file_item_iconbox--${fileType}`;
                const svgClass = `#icon-${fileType}`;
                const previewSize = bizShared?.getPreviewSize(size);
                const isImage = fileType === 'image';
                const $iconHtml = isImage
                    ? `<img class="${iconClass} form_item_file_item_iconbox--icon" src="${thumbUrl}" alt="${name}"></img>`
                    : `<i class="form_item_file_item_iconbox--icon">
                        <svg class="file_icon ${iconClass}">
                            <use xlink:href="${svgClass}"/>
                        </svg>
                    </i>`;
                valueHtml += `<li data-id="${item.id}" data-file="${id}" class="form_item_file_item">
                    <div class="form_item_file_item_iconbox">
                        ${$iconHtml}
                    </div>
                    <div class="form_item_file_item_textbox">
                        <p class="form_item_file_item_title">${name}</p>
                        <p  class="form_item_file_item_des">${previewSize}</p>
                    </div>
                </li>`;
                if (isImage) {
                    previewList.push({
                        src: previewUrl ?? thumbUrl,
                        id,
                        title: name,
                    });
                }
            }
            imageMap.set(item.id, {
                previewList,
            });
            valueHtml += '</ul></div>';
        }
        const uploadClass = item.siteFormFileList ? 'form_upload_item_wrap' : '';
        formContentHtml += `<div class="form_item_wrap ${uploadClass}">
									<div class="form_item_title">${Fai.encodeHtml(item.key)}：</div>
									<div class="form_item_content">${valueHtml}</div>
							</div>`;
    });
    var remarkWrap =
        isShowRemark == 'true'
            ? `<div class="form_item_wrap">
												<div class="form_item_title">${LS.remark_message}：</div>
												<div class="form_item_content">${remark}</div>
											</div>`
            : '';

    var statusDomString = showFormStatus
        ? `<div class="form_item_status_wrap">
				<div class="form_item_title">${LS.memberCurrentStatus}：</div>
				<div class="form_item_status jz_theme_font_color">${encodeHtmlStr(formStatusName)}</div>
		   </div>`
        : '';

    let payType = '';
    if (payData.isWXPay) {
        payType = `
            <svg class="wechat_form_pay_item_svg wechat_pay m_center_pay_icon">
                <use xlink:href="#icon_wechat_pay"></use>
            </svg>
            <span class="form_pay_wechat_txt">微信支付</span>
        `;
    } else if (payData.isAliPay) {
        payType = `
            <svg class="wechat_form_pay_item_svg ali_pay m_center_pay_icon">
                <use xlink:href="#icon_alipay"></use>
            </svg>
            <span class="form_pay_wechat_txt">支付宝支付</span>
        `;
    } else if (payData.isPayPal) {
        payType = `
            <svg class="wechat_form_pay_item_svg ali_pay m_center_pay_icon">
                <use xlink:href="#icon_paypal"></use>
            </svg>
            <span class="form_pay_wechat_txt">${window._store.state.lcid === 2052 ? 'PayPal支付' : 'PayPal'}</span>
        `;
    } else if (payData.isStripe) {
        payType = `
            <svg class="stripe_pay m_center_pay_icon">
                <use xlink:href="#icon_stripe"></use>
            </svg>
            <span class="form_pay_wechat_txt">${window._store.state.lcid === 2052 ? 'Stripe支付' : 'Stripe'}</span>
        `;
    } else if (payData.isPayssion) {
        payType = `
            <svg class="payssion m_center_pay_icon">
                <use xlink:href="#icon_payssion"></use>
            </svg>
            <span class="form_pay_wechat_txt">Payssion</span>
        `;
    }
    const payTypeHtml =
        Number(payData.price) > 0
            ? `
        <div class="form_item_pay_right">
            ${payType}
        </div>
    `
            : '';
    var payInfo = payData.openOnlinePay
        ? `
            <div class="form_item_wrap pay_wrap">
                <div class="form_item_title">${payData.payTxt}：</div>
                <div class="form_pay_price">￥${
                    typeof payData.price == 'number' ? payData.price.toFixed(2) : payData.price
                }
                </div>
                <div class="form_pay_text">${
                    payData.payStatus == 2 ? LS.havePaid || '已支付' : LS.nonPayment || '未支付'
                }</div>

                ${payTypeHtml}
            </div>
        `
        : '';
    const { submitShowId, id } = idData;

    const idItemHtml = submitShowId
        ? `
        <div class="form_item_status_wrap">
            <div class="form_item_title">${window._store.state.LS.form}ID：</div>
            <div class="form_item_status">${id}</div>
        </div>
    `
        : '';

    const buttonAction = canEdit
        ? `<button class="form_edit J_editButton jz_theme_bg_color">${LS.edit_form_result || '修改结果'}</button>`
        : `<button class="form_confirm jz_theme_bg_color">${LS.productScreenCommit}</button>`;

    var createListContent = function (footer = '') {
        return `
			<div class="form_list_content">
                ${idItemHtml}
				${statusDomString}
				${remarkWrap}
				${formContentHtml}
				${payInfo}
                ${footer}
			</div>
		`;
    };
    function popupWinReadyCallBack($el = $('.formDetail .form_item_file_list')) {
        if ($el.length) {
            $el.on('click.prevImage', '.form_item_file_item', function (e) {
                if ($(e.target).hasClass('form_item_file_item_iconbox--image')) {
                    const id = $(this).data('id');
                    const currentImageData = imageMap.get(id);
                    const fileId = $(this).data('file');
                    if (currentImageData?.previewList) {
                        let initialIndex = 0;
                        currentImageData?.previewList?.forEach((imageData, index) => {
                            if (imageData?.id === fileId) {
                                initialIndex = index;
                            }
                        });
                        slideshowView({
                            id: `slideshowView_${id}_${fileId}`,
                            previewSrcList: currentImageData?.previewList,
                            initialIndex,
                            zIndex: 10002,
                            logEventConfig: {
                                event: 'jz_hdpzj',
                                properties: {
                                    jz_content_terminal: '自适应-会员中心-表单详情',
                                    jz_version: window._store.state.jzVersion,
                                },
                            },
                        });
                    }
                }
            });
        }
    }

    function createFormEditComponent() {
        const FormEditComponent = window.Vue.extend(FormDetailEdit);
        const FormEditInstance = new FormEditComponent({
            store: window._store,
            propsData: {
                value: true,
                formSubmitId: idData.id,
                submitContentList: formRenderList,
            },
        });
        return FormEditInstance;
    }

    if ($(window).width() > 768) {
        const htmlContent = `
            ${createListContent()}
			${buttonAction}
        `;
        Site.popupBox({
            title: LS.memberFormDetail,
            htmlContent,
            width: 674,
            height: 680,
            extClass: 'formDetail',
            boxId: '10001',
            popupBoxZIndex: 9300,
            maskClosable: true,
            onMaskClose() {
                commonObj.flag = true;
            },
            closeFunc() {
                commonObj.flag = true;
            },
            popupWinReadyCallBack,
        });

        const removePopup = () => {
            commonObj.flag = true;
            $('#popupBg10001').remove();
            $('#popupBox10001').remove();
        };

        $('.formDetail .form_confirm').click(removePopup);

        $('.formDetail .J_editButton').on('click', function () {
            $('.form_list_content').hide();
            $('.J_editButton').hide();

            const FormEditInstance = createFormEditComponent();

            FormEditInstance.$on('submit-success', function () {
                removePopup();
            });
            $('#popupBg10001, #popupBox10001').css('z-index', 9033); // 默认的 z-index 会 遮挡 select
            $('#popupBoxContent10001').append(FormEditInstance.$mount().$el);
        });

        var height = 0;
        $('.form_item_wrap').each(function (index, item) {
            height += $(item).outerHeight(true);
        });
        height =
            $('.form_item_status_wrap').outerHeight(true) == null
                ? height
                : height + $('.form_item_status_wrap').outerHeight(true);
        if ($('.form_list_content').height() <= height) {
            $('.formDetail .formMSG').addClass('formScroll');
        }
    } else {
        const buttonAction = canEdit
            ? `<button class="form_edit J_editButton jz_theme_border_color jz_theme_font_color">${
                  LS.edit_form_result || '修改结果'
              }</button>`
            : '';

        let mobiPopupBox = $(`<div class="mobi_member_form_detail">
									<div class="form_detail_header">
											<i class="header_back faisco-icons-S000107"></i>
											${formName}
									</div>
								</div>`);

        mobiPopupBox.append($(createListContent(buttonAction)));
        $('body').append(mobiPopupBox);
        window.requestAnimationFrame(() => {
            popupWinReadyCallBack($('.mobi_member_form_detail .form_item_file_list'));
        });
        // 绑定事件
        $('.mobi_member_form_detail .header_back').click(function () {
            $('.mobi_member_form_detail').remove('');
            commonObj.flag = true;
        });
        $('.mobi_member_form_detail .J_editButton').on('click', function () {
            $('.mobi_member_form_detail .form_list_content').hide();

            const FormEditInstance = createFormEditComponent();
            FormEditInstance.$on('submit-success', function (submitId) {
                const $el = $(`.m_member_form_list .form_item_right[data-id=${submitId}]`);
                const waitForTips = 500; // ms
                if ($el.length > 0) {
                    commonObj.flag = true;
                    setTimeout(() => {
                        handleClickFormItem($el[0]);
                    }, waitForTips);
                }
            });

            $('.mobi_member_form_detail').append(FormEditInstance.$mount().$el);
        });
    }
}
function renderFormList(pageObj, isAll) {
    let formTableWrap = $('.m_member_center_form_wrap .m_member_center_form');
    formTableWrap.find('.m_member_form_list').remove();
    let statusUpId = pageObj.pageParam.statusUpId;
    let showRed = false;
    let itemShowRed = [];
    pageObj.pageParam.siteFormSubmitList.forEach((item, index) => {
        if (!showRed && !item.haveRead && item.showRemark) {
            showRed = true;
        }
        // 控制每一项的红点
        if (item.showRemark && !item.haveRead) {
            itemShowRed.push(index);
        }
        let date = new Date(item.createTime);
        let year = date.getFullYear();
        let month = date.getMonth() >= 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
        let day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
        let statusDomString = item.showFormStatus
            ? `<div class='form_item_status form_item form_item_status_content jz_theme_font_color'>${encodeHtmlStr(
                  item.formStatusName
              )}</div>`
            : "<div class='form_item_status form_item form_item_status_content jz_theme_font_color'>—</div>";
        let formItem = $(`
                    <div class='m_member_form_item_wrap m_member_form_list'>
                        <div class='form_item_left form_item'>${year}-${month}-${day}</div>
                        <div class='form_item_center form_item'>${encodeHtmlStr(item.name)}</div>
                            ${statusDomString}
                        <div class='form_item_right jz_theme_hover_font_color form_item' data-remark='${
                            item.showRemark
                        }' data-id='${item.id}'>${LS.memberViewDetail}<span class="view_detail"></span></div>
                    </div>`);
        formTableWrap.append(formItem);
    });
    // 达到版本后才可显示
    if ($('#memberCenterForms .member_mine_forms').attr('data-allowremark') == 'true') {
        // 每个表单项的红点
        itemShowRed.forEach((item) => {
            $($('.view_detail')[item]).addClass('view_detail_active');
            if (isAll == false) {
                $($('.m_member_form_list .form_item_center')[item]).addClass('form_item_center_active');
            }
        });
        if (statusUpId) {
            $('.red_tips').addClass('red_tips_active');
            $('.mobi_remark_tips').addClass('mobi_remark_tips_active');
            if (statusUpId == 1) {
                $('.mobi_remark_tips').text(LS.member_status_udateTips);
            }
        }
    }
}

function renderPagination(data) {
    const { pageParam } = data;
    const { totalSize } = pageParam;

    if ($('.pagination_compon_wrap').length > 0) {
        $('.pagination_compon_wrap').remove();
    }

    if (totalSize <= 10) {
        return;
    }

    if (window._store.state.device === 'mobi') {
        return;
    }

    const formTableWrap = $('.m_member_center_form_wrap .m_member_center_form');
    const vm = new Vue({
        components: { PaginationComponent },
        data() {
            return {
                pageCount: Math.ceil(totalSize / 10),
                currentPage: 1,
            };
        },
        store: window._store,
        methods: {
            currentChange(page) {
                this.currentPage = page;
                getSubmitList({
                    pageNo: page,
                    pageSize: 10,
                }).then((pageObj) => {
                    if (!pageObj.pageParam) {
                        return;
                    }
                    renderFormList(pageObj, false);
                    initFormDetail(8);
                    $('.pagination_compon_wrap').appendTo(formTableWrap);
                });
            },
        },
        template: `
            <PaginationComponent
                :moduleId="8"
                :pageCount="pageCount"
                :currentPage="currentPage"
                :styleIndex="1"
                @currentChange="currentChange"
            ></PaginationComponent>
        `,
    });
    formTableWrap.append(vm.$mount().$el);
}

function loadSubmitList() {
    return getSubmitList({}).then((res) => {
        if (res.success === false || !res.pageParam) {
            return [];
        } else {
            return res.pageParam.siteFormSubmitList ?? [];
        }
    });
}

function hideTabRedTip(submitList) {
    var hideRedTip = true;
    if (submitList.length > 0) {
        for (var i = 0; i < submitList.length; i++) {
            if (i >= 30 || !hideRedTip) {
                break;
            }

            hideRedTip = submitList[i].haveRead == false && submitList[i].showRemark ? false : true;
        }

        if (hideRedTip) {
            // 隐藏
            $('.red_tips').removeClass('red_tips_active');
            $('.mobi_remark_tips').removeClass('mobi_remark_tips_active');
        }
    }
}
export function changeSubmitStatus() {
    changeSubmitStatusPost({
        statusUpId: 1,
    }).then((result) => {
        if (result.success) {
            resetRedTips();
        }
    });
}
function resetRedTips() {
    getSubmitList({}).then((pageObj) => {
        if (!pageObj.pageParam) {
            return;
        }
        let statusUpId = pageObj.pageParam.statusUpId;
        if (statusUpId == 0) {
            // 隐藏红点加提示
            $('.red_tips').removeClass('red_tips_active');
            $('.mobi_remark_tips').removeClass('mobi_remark_tips_active');
        } else if (statusUpId == 1) {
            // 有状态更新
            $('.mobi_remark_tips').text(LS.member_status_udateTips);
        } else {
            // 有备注更新
            $('.mobi_remark_tips').text(LS.member_status_udateTips);
        }
    });
}

/**
 * 退出登录
 */
export function logoutMember() {
    new Promise((resolve, reject) => {
        setLogoutMember({}).then((data) => {
            data.success ? resolve(data) : reject(data);
        });
    })
        .then(() => {
            window.location.reload();
        })
        .catch(() => {
            warningMessage(LS.memberProfileError, true);
        });
}

export function initFileUpload(list, moduleId) {
    list.forEach((item) => {
        const { pt } = item;
        if (pt !== 2) {
            return;
        }

        const { id, fileSizeLimit, fileUpInfo, previewLinkInfo } = item;

        if (!previewLinkInfo) {
            return;
        }

        memberFileUpload({
            moduleId,
            fileSizeLimit,
            fileUpInfo: fileUpInfo,
            previewLinkInfo,
            propId: id,
        });
    });
}

export async function popupFlowDetail(item) {
    const FlowDetailPopup = await import('./flowDetailPopup.vue');
    const popupComponent = FlowDetailPopup.default;
    const isPc = Comm.getDevice() === 'pc';
    if (isPc) {
        Vue.prototype.$modal.show({
            title: LS.flowDetail,
            width: '800px',

            cancelButtonText: LS.confirm,
            confirmButtonText: '',
            // content: `<div style="color:red;">test</div>`,
            content: {
                render(createElement) {
                    return createElement(popupComponent, {
                        props: {
                            item,
                            isMobi: false,
                        },
                    });
                },
            },
        });
    } else {
        let fragment = document.createElement('div');
        $(fragment).appendTo('body');
        new Vue({
            el: fragment,
            template: `<popupComponent :item="item" :visible="visible" :isMobi="isMobi" />`,
            components: {
                popupComponent,
            },
            data() {
                return {
                    isMobi: true,
                    item,
                    visible: true,
                };
            },
        });
    }
}
