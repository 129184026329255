import { createJumpLink } from '@/manage/shared/jumpLink/index.js';
import { getComputedTextColor, burnColor } from '@/components/utils.js';
const getButtonModuleDefaultStyle = function (style) {
    var themeColor = window._store.state.responsiveInfo.themeColor;

    switch (style) {
        case 0:
            return {
                prds: 4,
                //圆角
                pbg: {
                    //背景
                    y: 0,
                    //默认
                    c: themeColor, //背景色
                },
                phbg: {
                    //hover背景
                    c: themeColor,
                },
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 0,
                    //边框宽度
                    c: '#000',
                    //边框颜色
                    hc: '#000', //hover边框颜色
                },
                pft: {
                    //字体
                    y: 0,
                    //默认
                    s: 14,
                    //字体大小
                    f: '微软雅黑',
                    //字体样式
                    c: '#fff',
                    //字体颜色
                    hc: '#fff', //hover字体颜色
                },
            };

        case 1:
            return {
                prds: 20,
                //圆角
                pbg: {
                    //背景
                    y: 0,
                    //默认
                    c: themeColor, //背景色
                },
                phbg: {
                    //hover背景
                    c: '#fff',
                },
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 1,
                    //边框宽度
                    c: themeColor,
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
                pft: {
                    //字体
                    y: 0,
                    //默认
                    s: 14,
                    //字体大小
                    f: '微软雅黑',
                    //字体样式
                    c: '#fff',
                    //字体颜色
                    hc: themeColor, //hover字体颜色
                },
            };

        case 2:
            return {
                prds: 4,
                //圆角
                pbg: {
                    //背景
                    y: 0,
                    //默认
                    c: themeColor, //背景色
                },
                phbg: {
                    //hover背景
                    c: burnColor(themeColor, 5),
                },
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 0,
                    //边框宽度
                    c: themeColor,
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
                pft: {
                    //字体
                    y: 0,
                    //默认
                    s: 14,
                    //字体大小
                    f: '微软雅黑',
                    //字体样式
                    c: '#fff',
                    //字体颜色
                    hc: '#fff', //hover字体颜色
                },
            };

        case 3:
            return {
                prds: 20,
                //圆角
                pbg: {
                    //背景
                    y: 0,
                    //默认
                    c: '#fff', //背景色
                },
                phbg: {
                    //hover背景
                    c: '#fff',
                },
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 1,
                    //边框宽度
                    c: '#cccccc',
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
                pft: {
                    //字体
                    y: 0,
                    //默认
                    s: 14,
                    //字体大小
                    f: '微软雅黑',
                    //字体样式
                    c: '#333333',
                    //字体颜色
                    hc: themeColor, //hover字体颜色
                },
            };

        case 4:
        case 5:
            return {
                prds: 0,
                //圆角
                pbg: {
                    //背景
                    y: 0,
                    //默认
                    c: '#fff', //背景色
                },
                phbg: {
                    //hover背景
                    c: themeColor,
                },
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 1,
                    //边框宽度
                    c: themeColor,
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
                pft: {
                    //字体
                    y: 0,
                    //默认
                    s: 14,
                    //字体大小
                    f: '微软雅黑',
                    //字体样式
                    c: themeColor,
                    //字体颜色
                    hc: '#fff', //hover字体颜色
                },
            };
    }
};

const getButtonPresetData = function (name) {
    const newMobiResponsiveConversion = window._store.state.newMobiResponsiveConversion,
        moduleInfo = {
            style: 8,
            name: name,
            content: '按钮',
            pattern: JSON.stringify({
                pc: {
                    by: 1,
                    //隐藏标题栏
                    ml: 0.41667,
                    //左右带有间距，居中
                    mr: 0.41667, //左右带有间距，居中
                },
                mobi: {
                    by: 1,
                    //隐藏标题栏
                    ml: 0.3,
                    //375下为14
                    mr: 0.3,
                    //375下为14
                    mt: newMobiResponsiveConversion ? 0.02666666666666667 : 0.02666,
                    //375下为10
                    mb: newMobiResponsiveConversion ? 0.013333333333333334 : 0.01333, //375下为 5
                },
            }),
            prop0: 0,
            //默认样式
            privatePattern: JSON.stringify({
                pc: getButtonModuleDefaultStyle(0), //默认样式
            }),
        };
    return moduleInfo;
};
const buttonModuleEdit = function (id) {
    const moduleId = `module${id}`,
        $module = $(`#${moduleId}`),
        moduleWidth = $module.width(),
        moduleOffset = $module[0] ? $module[0].getBoundingClientRect() : {};

    window._store.commit('manage/editPanel/editPanelChange', {
        type: 'smallEditPanel',
        id: id,
        active: true,
        title: '按钮样式',
        data: window._store.state.modules[moduleId],
        left: parseInt(moduleOffset.left + moduleWidth + 80),
        top: parseInt(moduleOffset.top - 360),
        page: 'basic',
        compon: 'buttonEditPanel',
    });
}; // 存储当前模块id，用于发送fdp

const calcButtonStyle = function (style) {
    var styleAttr = {
        'border-radius': `${style.prds}px`,
    };
    if (style.pbg.y == 1) {
        if (style.pbg.c.indexOf('linear-gradient') > -1) {
            styleAttr.backgroundImage = style.pbg.c;
        } else {
            styleAttr.background = style.pbg.c;
        }
    }

    if (style.pbd.y == 1) {
        styleAttr['border-width'] = `${style.pbd.w}px`;
        styleAttr['border-color'] = style.pbd.c;
        styleAttr['border-style'] = 'solid';
    }
    return styleAttr;
};

const calcButtonHoverStyle = function (style, type) {
    var hoverStyle = {};

    if (style.pbd.y == 1) {
        hoverStyle['border-color'] = style.pbd.hc;
    }
    if (style.pbg.y == 0) {
        if (type == 2) {
            hoverStyle.backgroundColor = burnColor(window._store.state.responsiveInfo.themeColor, 5);
        }
    }
    return hoverStyle;
};

const calcButtonTextStyle = function (style) {
    var styleAttr = {};
    if (style.pft.y) {
        styleAttr['font-size'] = `${style.pft.s}px`;
        styleAttr['font-family'] = style.pft.f;
        styleAttr = {
            ...styleAttr,
            ...getComputedTextColor(style.pft.c),
        };
    }
    return styleAttr;
};

const calcButtonTextHoverStyle = function (style) {
    var hoverStyle = {};

    if (style.pft.y == 1) {
        hoverStyle = {
            ...hoverStyle,
            ...getComputedTextColor(style.pft.hc),
        };
    }

    return hoverStyle;
};

const buttonModuleSetLink = function (module) {
    createJumpLink('button', module, { linkProp: 'prop3' });
};

export {
    getButtonModuleDefaultStyle,
    buttonModuleEdit,
    calcButtonStyle,
    calcButtonHoverStyle,
    calcButtonTextStyle,
    calcButtonTextHoverStyle,
    buttonModuleSetLink,
    getButtonPresetData,
};
