// @unocss-include
import { digGet, memorize } from '@jz/utils';
import { logDog } from '@/site/shared/log/index.js';
import { getModuleTargetName } from '@/components/modules/shared/constant.js';
import { store } from '@/store/store';
export const isDef = (n) => {
    return typeof n !== 'undefined';
};
const getAllModuleName = (id, key = 'id') => {
    return getModuleTargetName({
        id,
        key,
        targetKey: 'moduleComponentName',
    });
};

const getModuleStyleName = (Style, id) => {
    if (!Style && id > 0) {
        return getSysModuleNameById(id);
    }
    return getAllModuleName(Style);
};

const getSysModuleNameById = (id) => {
    return getAllModuleName(id, 'sysId');
};

/*
 * 操作多级对象属性,返回一个vue计算属性,用于操作vue多级对象属性
 * Comm.operMultiLevelObjectAttr('module.flag', getFunc, setFunc)
 * operAttr 需要操作的多级属性 如'module.flag'
 * getFunc 获取数据时执行的函数 在函数中this为操作的对象，第一个参数key为操作的属性（如flag）
 * setFunc 设置数据时执行的函数 在函数中this为操作的对象，第一个参数key为操作的属性（如flag），第二个参数newVal为需要设置的新值
 */
const operMultiLevelObjectAttr = function (operAttr, getFunc, setFunc) {
    function operObjAttr(func) {
        return function () {
            let key = '';
            let operObj = this;
            let attrs = operAttr.split('.');
            key = attrs.pop();
            for (const attr of attrs) {
                operObj = operObj[attr];
            }
            return func.call(operObj, key, ...arguments, this);
        };
    }

    return {
        get: operObjAttr(getFunc),
        set: operObjAttr(setFunc),
    };
};

//设置flag某位的值
const setBitMemory = function (flag, bit, val) {
    if (val) {
        flag |= bit;
    } else {
        flag &= ~bit;
    }
    return flag;
};

//获取flag某位的值
export const getBitMemory = function (flag, bit) {
    return (flag & bit) == bit;
};

/*
 * 绑定flag列表到vue的计算属性中，可读写
 *  computed:{
 *    ...O.mapFlag({
 *      useFontIcon: 0x1,
 *      otherFlag: ...
 *    }),
 *    otherAttr:...
 *  }
 * flagKey参数同O.bindFlag方法的flagKey参数, 默认使用this.module.flag
 */
const mapFlag = function (flagDefs, flagKey) {
    const flagComputeds = {};
    for (const [key, bit] of Object.entries(flagDefs)) {
        flagComputeds[key] = bindFlag(bit, flagKey);
    }
    return flagComputeds;
};

/*
 * 绑定flag项到vue的计算属性中，可读写
 * computed:{ useFontIcon: O.bindFlag(0x1), otherAttr:... }
 * flagKey不传默认使用this.module.flag作为flag, 传'moduleConf.flag1'为this.moduleConf.flag1
 */
const bindFlag = function (bit, flagKey = 'module.flag') {
    return operMultiLevelObjectAttr(
        flagKey,
        function (key) {
            //get
            return getBitMemory(this[key], bit);
        },
        function (key, newVal) {
            //set
            this[key] = setBitMemory(this[key], bit, newVal);
        }
    );
};

// 前两个参数是sort方法的两个参数，第三个参数可有可无，为排序属性，即要排序的字段和排序的方向；如果第三个字段为空，则按照全属性升序排序
const objSort = function objSort(item1, item2) {
    // "use strict";
    var props = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        props[_i - 2] = arguments[_i];
    }
    var cps = []; // 存储排序属性比较结果。
    // 如果未指定排序属性，则按照全属性升序排序。
    var asc = true;
    const rep = /\d_\d|\d-\d/,
        replaceRep = /_|-/g;
    if (props.length < 1) {
        for (var p in item1) {
            if (item1[p] > item2[p]) {
                cps.push(1);
                break; // 大于时跳出循环。
            } else if (item1[p] === item2[p]) {
                cps.push(0);
            } else {
                cps.push(-1);
                break; // 小于时跳出循环。
            }
        }
    } else {
        for (var i = 0; i < props.length; i++) {
            var prop = props[i];
            // eslint-disable-next-line guard-for-in
            for (var o in prop) {
                asc = prop[o] === 'asc' ? 1 : -1;
                var value1 = typeof item1[o] === 'undefined' ? 0 : item1[o];
                var value2 = typeof item2[o] === 'undefined' ? 0 : item2[o];
                rep.test(value1) && (value1 = value1.replace(replaceRep, '.'));
                rep.test(value2) && (value2 = value2.replace(replaceRep, '.'));
                if (!isNaN(Number(value1)) && !isNaN(Number(value2))) {
                    // 对纯数字的字符串做额外处理, @author Jy
                    value1 = Number(value1);
                    value2 = Number(value2);
                }
                if (typeof value1 === 'string' && typeof value2 === 'string') {
                    // localeCompare 开启 指定使用数字排序, 像这样 "1" < "2" < "10";
                    return value1.localeCompare(value2, 'zh', {
                        numeric: true,
                    }) >= 0
                        ? asc
                        : -asc;
                }
                if (value1 > value2) {
                    return asc;
                } else if (value1 < value2) {
                    return -asc;
                }
            }
        }
        return 0;
    }
    for (var j = 0; j < cps.length; j++) {
        if (cps[j] === 1 || cps[j] === -1) {
            return cps[j];
        }
    }

    return false;
};

const decodeUrl = function (url) {
    var backUrl = '';
    try {
        // 如果decodeURIComponent失败, 会爆Uncaught URIError
        backUrl = typeof url === 'undefined' ? '' : decodeURIComponent(url);
    } catch (e) {
        backUrl = '';
    }
    return backUrl;
};

const format = function () {
    var s = arguments[0];
    for (var i = 0; i < arguments.length - 1; i++) {
        var reg = new RegExp('\\{' + i + '\\}', 'gm');
        s = s && s.replace(reg, arguments[i + 1]);
    }
    return s;
};

//  解码在html中属性值，例如<div title="xxx">
const decodeHtmlAttr = function (html) {
    return html && html.replace
        ? html
              .replace(/&#x22;/gi, '"')
              .replace(/&#x27;/gi, "'")
              .replace(/&#x3c;/gi, '<')
              .replace(/&#x3e;/gi, '>')
              .replace(/&#x26;/gi, '&')
              .replace(/&#5c;/gi, '\\')
        : html;
};

const flatten = function (arr, depth = 1) {
    return arr.reduce((a, v) => a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v), []);
};

const imageSettingtMixin = {
    computed: {
        _mixin_model() {
            return this.module.privatePattern[this.device];
        },
        _mixin_itemStyle() {
            var style = {};

            if (!this.isMobi) {
                var rate = '25%',
                    defaultMargin = '';
                defaultMargin = '20px';
                if (this.module.prop0 === 3) {
                    defaultMargin = '22px';
                }
                rate = this.module.prop0 != 2 ? '25%' : '100%';
                if (this.model.pmt == 1) {
                    style.margin = this.model.pmv / 2 + 'px ' + this.model.pmh / 2 + 'px';
                    defaultMargin = this.model.pmh + 'px';
                }
                if (this.module.prop0 != 2) {
                    if (this.model.rlt == 1) {
                        rate = Math.floor((100 * 100) / this.model.rl) / 100 + '%';
                        if (this.model.pmt == 1) {
                            style.width = 'calc(' + rate - (this.model.pmv + 'px') + ')';
                        }
                    }
                }
                style.width = 'calc(' + rate + ' - ' + defaultMargin + ')';
            }

            if (this.isMobi) {
                if (this.module.prop0 === 0) {
                    // 样式一支持单独设置手机每行个数
                    if (this.model.rlt === 1) {
                        style.width = `${100 / this.model.rl - 3.6}%`;
                    }
                }
            }

            return style;
        },
        _mixin_picStyle() {
            if (!this.manageMode) {
                return;
            }

            var style = {};
            if (this.model.psy == 1) {
                style.backgroundSize = 'cover';
            } else if (this.model.psy == 2) {
                style.backgroundSize = 'auto';
            }
            return style;
        },
        _mixin_itemSizeStyle() {
            var style = {};
            if (this.model.pht === 1) {
                // 图片高度
                style.paddingBottom = Math.floor(this.module.privatePattern['pc'].ph * 10000) / 100 + '%';
            }
            return style;
        },
    },
};

const newsMixin = {
    computed: {
        _mixin_imgStyle() {
            if (!this.manageMode) return {};

            var style = {};
            var type = this.module.prop0;

            // style.backgroundImage = 'url(' + this.item.imgPath + ')';
            if (!this.isShowPic) {
                return style;
            }

            if (this.device != 'mobi') {
                //仅pc的样式
                if (this.privatePattern.pps == 1) {
                    style.backgroundSize = 'cover';
                } else if (this.privatePattern.pps == 2) {
                    style.backgroundSize = 'auto';
                }

                //两列的样式
                if (type != 0 && this.privatePattern.ppht == 1) {
                    style.paddingTop = this.privatePattern.pph * 100 + '%';
                }
            }

            if (this.device == 'mobi') {
                //上下图文样式手机高度跟随pc
                if (type == 1 && this.pcPrivatePattern.ppht == 1) {
                    style.paddingTop = this.pcPrivatePattern.pph * 100 + '%';
                }
            }

            return style;
        },
    },
};

export const getComputedBg = function (bg = {}, isLazy, options = {}) {
    let bGstyle = {},
        repeatDict = ['repeat', 'no-repeat', 'repeat-x', 'repeat-y'],
        positionDict = [
            'top left',
            'top center',
            'top right',
            'center left',
            'center center',
            'center right',
            'bottom left',
            'bottom center',
            'bottom right',
        ],
        sizeDict = ['auto', '100% 100%', '100%', 'cover', 'contain'];

    if (bg.y === 0) {
        bGstyle.background = '';
    } else if (bg.y == 1) {
        bGstyle.background = 'none';
    } else if (bg.y == 2) {
        if (bg.d == 0) {
            //纯色
            bGstyle.backgroundColor = bg.c;
            if (options.manageV2) {
                bGstyle.backgroundImage = 'none';
            }
        } else if (bg.d == 1) {
            //渐变色
            bGstyle.backgroundImage = '-webkit-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bGstyle.backgroundImage = '-o-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bGstyle.backgroundImage = '-moz-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bGstyle.backgroundImage = 'linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bGstyle.backgroundColor = 'initial';
        } else if (bg.d == 2) {
            if (bg.f) {
                bGstyle.backgroundSize = sizeDict[bg.s];
                if (!isLazy) {
                    bGstyle.backgroundImage = 'url(' + bg.path + ')';
                }
                bGstyle.backgroundColor = bg.c;
                if (bg.s == 4) {
                    bGstyle.backgroundRepeat = 'no-repeat';
                } else if (bg.s == 3) {
                    bGstyle.backgroundRepeat = 'no-repeat';
                } else if (bg.s == 2) {
                    bGstyle.backgroundRepeat = 'no-repeat';
                } else {
                    bGstyle.backgroundRepeat = repeatDict[bg.r];
                }
                if (bg.s == 4) {
                    bGstyle.backgroundPosition = 'center';
                } else if (bg.s == 3) {
                    bGstyle.backgroundPosition = 'center';
                } else {
                    bGstyle.backgroundPosition = positionDict[bg.p];
                }
            }
        } else if (bg.d == 3) {
            // image
            if (bg.f) {
                if (options.manageV1) {
                    //注意，这里为了统一getComputedBg，增加的兼容变量
                    if (bg.tc || !isLazy) {
                        bGstyle.backgroundImage = 'url(' + bg.path + ')';
                    }
                } else {
                    bGstyle.backgroundImage = 'url(' + bg.path + ')';
                }
            }
            if (bg.tc) {
                bGstyle.backgroundImage =
                    (bGstyle.backgroundImage ? bGstyle.backgroundImage + ',' : '') +
                    'linear-gradient(' +
                    bg.a +
                    'deg, ' +
                    bg.c +
                    ' ' +
                    bg.cp +
                    '%, ' +
                    bg.tc +
                    ' ' +
                    bg.tcp +
                    '%)';
                bGstyle.backgroundColor = 'initial';
            }
            // color
            if (!bg.tc) {
                bGstyle.backgroundColor = bg.c;
            }

            if (options.manageV2) {
                if (!bg.f && !bg.tc) {
                    bGstyle.backgroundImage = 'none';
                }
            }
            // size
            bGstyle.backgroundSize = sizeDict[bg.s];
            // repeat
            if (bg.s == 4) {
                bGstyle.backgroundRepeat = repeatDict[1];
            } else if (bg.s == 3) {
                bGstyle.backgroundRepeat = repeatDict[1];
            } else if (bg.s == 2) {
                bGstyle.backgroundRepeat = repeatDict[1];
            } else {
                bGstyle.backgroundRepeat = repeatDict[bg.r];
            }
            // position
            if (bg.s == 4) {
                bGstyle.backgroundPosition = positionDict[4];
            } else if (bg.s == 3) {
                bGstyle.backgroundPosition = positionDict[4];
            } else if (bg.s == 1) {
                bGstyle.backgroundPosition = positionDict[3];
            } else {
                bGstyle.backgroundPosition = positionDict[bg.p];
            }
        }
    }
    return bGstyle;
};

const extend = function (to, _from) {
    for (var key in _from) {
        to[key] = _from[key];
    }
    return to;
};

export const getComputedTextColor = function (color) {
    const textColorStyle = {};
    if (color) {
        if (color.indexOf('linear-gradient') > -1) {
            textColorStyle.backgroundImage = color;
            textColorStyle.color = 'transparent';
            textColorStyle['-webkit-text-fill-color'] = 'transparent';
            textColorStyle['-webkit-background-clip'] = 'text';
        } else {
            textColorStyle.backgroundImage = '';
            textColorStyle.color = color;
            textColorStyle['-webkit-text-fill-color'] = '';
            textColorStyle['-webkit-background-clip'] = '';
        }
    }
    return textColorStyle;
};

export const getComputedFont = (fontPattern) => {
    let fontStyle = {};
    if (fontPattern.y) {
        fontStyle = {
            fontSize: fontPattern.s ? fontPattern.s + 'px' : '',
            fontFamily: fontPattern.f,
            fontStyle: fontPattern.i == 1 ? 'italic' : 'normal',
            fontWeight: fontPattern.w == 1 ? 'bold' : 'normal',
            textDecoration: fontPattern.d == 1 ? 'underline' : 'none',
        };
        const textColorStyle = getComputedTextColor(fontPattern.c);
        Object.assign(fontStyle, textColorStyle);
    }
    return fontStyle;
};

const createMergeFunction = function (extendRule = {}) {
    const defaultRule = {
        Object(originalObject = {}, targetObject = {}) {
            return originalObject.y === 0 && targetObject.y !== 0;
        },
        Number(originalNum = -1, targetNum = -1) {
            return (originalNum === 0 || originalNum === -1) && targetNum !== 0 && targetNum !== -1;
        },
        String() {},
    };

    const innerRule = Object.assign({}, defaultRule, extendRule);

    const getType = function (o) {
        const str = Object.prototype.toString.call(o).split(' ')[1];
        return str.substring(0, str.length - 1);
    };

    return function (userRule = {}, originalObject = {}, targetObject = {}) {
        const keys = Object.keys(originalObject);
        const obj = {};

        keys.forEach((k) => {
            const originalVal = originalObject[k];
            const targetVal = targetObject[k];
            const valType = getType(originalVal);
            let canMerge = false;

            if (Object.keys(innerRule).indexOf(valType) === -1) {
                return;
            }

            if (userRule[k]) {
                const params = [originalVal, targetVal, originalObject, targetObject];
                canMerge = userRule[k].handler && userRule[k].handler.apply(null, params);
            } else {
                canMerge = innerRule[valType].call(null, originalVal, targetVal);
            }

            obj[k] = canMerge ? targetVal : originalVal;
        });

        return obj;
    };
};

const mergeWithRule = createMergeFunction();

const LogDog = {
    shallowDiff(initialVal, value, keys = []) {
        // 只做一层的值diff
        if (keys.length > 0) {
            return keys.filter((k) => {
                const keyArr = k.split('.');
                if (keyArr.length > 1) {
                    try {
                        let initial = initialVal;
                        let val = value;

                        keyArr.forEach((key) => {
                            initial = initial[key];
                            val = val[key];
                        });
                        return initial !== val;
                    } catch (error) {
                        return false;
                    }
                }

                return initialVal[k] !== value[k]; // 暂时不处理非值数据
            });
        } else {
            return Object.keys(value).filter((k) => initialVal[k] !== value[k]);
        }
    },
    mapping(diffKeys = [], dogIdMapping = {}) {
        return diffKeys.reduce((acc, diffKey) => {
            if (dogIdMapping[diffKey]) {
                acc[diffKey] = dogIdMapping[diffKey];
            }
            return acc;
        }, {});
    },
    run(srcId, mapping = {}) {
        Object.values(mapping).forEach((id) => logDog(srcId, id));
        return this;
    },
};

export const createUrlArgsOnclickStr = (args, _openRemoveUrlArgs = false, hashRemoved = false) => {
    if (!args) return null;
    const argsStr = typeof args === 'string' ? args : JSON.stringify(args);

    if (_openRemoveUrlArgs || hashRemoved) {
        return `Fai.top.JZ.setUrlArgToCookie(this, ${argsStr}); return false;`;
    }
    return null;
};

export const isPhotoSlides = (module) => {
    return digGet(module, 'commProp.ps.o') === 1;
};

export const setQueryString = (url, key, val) => {
    if (typeof url !== 'string') {
        return '';
    }
    //临时checkme-coyife  -处理fromColId不加入链接
    if (key == 'fromColId') {
        return url;
    }
    const pattern = key + '=([^&]*)',
        replaceText = key + '=' + val;

    if (url.match(pattern)) {
        let tmp = '/(' + key + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        if (url.match('[?]')) {
            return url + '&' + replaceText;
        } else {
            return url + '?' + replaceText;
        }
    }
};

export const getStyleDataByDevice = ({
    mobiData,
    pcData,
    isMobi,
    typeKey = 'y',
    mobiTypeDefaultValue = 0,
    mobiDefaultIsExtendPc = true,
    // 文字不继承字体大小，其他都继承（例如颜色、斜体会继承）
    isTextNotExtendFontSize = false,
    // 是否是新模块
    isNewModule = true,
}) => {
    if (isMobi) {
        if (typeof mobiData !== 'object') {
            if (mobiDefaultIsExtendPc && mobiData === mobiTypeDefaultValue) {
                return pcData;
            }
            return mobiData;
        }
        if (mobiDefaultIsExtendPc && mobiData[typeKey] === mobiTypeDefaultValue) {
            if (isTextNotExtendFontSize && isNewModule) {
                return Object.assign({}, pcData, { s: '' });
            }
            return pcData;
        }
        return mobiData;
    }
    return pcData;
};

export const getTextAlignStyle = (type) => {
    const value = {
        0: '',
        1: 'left',
        2: 'center',
        3: 'right',
    }[type];
    return { 'text-align': value };
};

// 换行样式
export const getWrapStyle = ({ y: type = 0, l: line = 1 }) => {
    const styles = {};
    if (type === 0) {
        // 默认
        return styles;
    } else {
        styles.display = '-webkit-box';
        styles.overflow = 'hidden';
        styles['-webkit-line-clamp'] = String(line);
        styles['-webkit-box-orient'] = 'vertical';
        styles['white-space'] = 'normal';
        styles['word-break'] = 'break-word';
        styles['max-height'] = 'none';
    }
    if (type === 2) {
        // 不换行
        styles['-webkit-line-clamp'] = '1';
        styles['word-break'] = 'break-all';
    }
    return styles;
};

export const isNewModuleAfter20210707 = (module = {}) => {
    return module?.commProp?.unst ?? false;
};

export const encodeHtmlStr = (html, { notReplaceToNbsp = false } = {}) => {
    if (html) {
        html = html
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\\/g, '&#92;')
            .replace(/'/g, '&#39;')
            .replace(/"/g, '&quot;')
            .replace(/\n/g, '<br/>')
            .replace(/\r/g, '');
        if (!notReplaceToNbsp) {
            html = html.replace(/ /g, '&nbsp;');
        }
    }
    return html;
};

export const decodeHtmlStr = (html) => {
    return html && html.replace
        ? html
              .replace(/&nbsp;/gi, ' ')
              .replace(/&lt;/gi, '<')
              .replace(/&gt;/g, '>')
              .replace(/&#92;/gi, '\\')
              .replace(/&#39;/gi, "'")
              .replace(/&quot;/gi, '"')
              .replace(/<br\/>/gi, '\n')
              .replace(/&amp;/gi, '&')
        : html;
};

export const decodeHtml = decodeHtmlStr;

export const getFirstGradientColor = function (color) {
    let ret = color || '';
    if (color) {
        if (color.indexOf('linear-gradient') > -1) {
            const re = /.*linear-gradient\(.*?(rgb[a]?\(.*?\)).*?\).*/.exec(color);
            if (re) {
                ret = re[1];
            }
        }
    }
    return ret;
};

const isPlainObject = (obj) => {
    return Object.prototype.toString.call(obj) === '[object Object]';
};

const getDisplayData = function (device, pc, mobi, defkey = 'y', defVal = 0) {
    //对象的话就要对比默认属性
    if (isPlainObject(pc)) {
        return device == 'mobi' && mobi[defkey] != defVal ? mobi : pc;
    } else {
        //基本类型的话就直接对比
        return device == 'mobi' && mobi != defVal ? mobi : pc;
    }
};

const isSectionId = function (id) {
    return id >= 150 && id < 200;
};
const isTemporaryModuleId = function (id) {
    return id >= 200 && id < 300;
};
const isTemporaryRowId = function (id) {
    return id >= 50 && id < 100;
};

const hexToRGB = function (hexValue) {
    hexValue = hexValue.replace('#', '').toLowerCase();
    if (hexValue.length == 3) {
        let hexValueArr = new Array();
        let i = 3;
        while (i--) {
            hexValueArr[i] = hexValue.charAt(i) + '' + hexValue.charAt(i);
        }
        hexValue = hexValueArr.join('');
    }

    if (hexValue.length == 6) {
        var RGBValue = hexValue.match(/[0-9a-f]{2}/g);
        if (RGBValue && RGBValue.length == 3) {
            let i = 3;
            while (i--) {
                RGBValue[i] = parseInt(RGBValue[i], 16);
            }
            return RGBValue;
        } else {
            return false;
        }
    }
};

const rgbToHex = function (rgb) {
    if (/#/g.test(rgb)) return rgb;

    rgb = rgb.replace(/[rgba()]/g, '').split(','); //[r,g,b]

    var hex = ['#'];
    for (var i = 0, hexpart; i < 3; i++) {
        hexpart = parseInt(rgb[i]).toString(16);
        if (hexpart.length === 1) {
            hexpart = '0' + hexpart;
        }
        hex.push(hexpart);
    }
    return hex.join('');
};

export const burnColor = function (colorInput, level) {
    var inputRGB = hexToRGB(rgbToHex(colorInput)); //优化点
    // 颜色格式校验
    if (!inputRGB || !level || isNaN(level)) {
        return '';
    }
    level = Math.max(level, 0);
    level = Math.min(level, 100);

    for (var i = 0; i < inputRGB.length; i++) {
        inputRGB[i] = Math.floor(inputRGB[i] - inputRGB[i] * (level / 100)).toString(16);
        if (inputRGB[i].length == 1) {
            inputRGB[i] = '0' + inputRGB[i];
        }
    }
    var outputHex = '#' + inputRGB.join('');
    return outputHex;
};

export const checkZoom = memorize(() => {
    if (typeof document === 'undefined') return false;
    const meta = Array.from(document.querySelectorAll('meta[name="viewport"]'))[0];
    if (!meta) return false;

    const { content } = meta;

    let maxScale = 1,
        minScale = 1,
        userScalable = true;

    content.split(',').forEach((item) => {
        const [key, value] = item.trim().split('=');
        switch (key) {
            case 'maximum-scale':
                maxScale = parseFloat(value);
                break;
            case 'minimum-scale':
                minScale = parseFloat(value);
                break;
            case 'user-scalable':
                userScalable = value === 'yes';
                break;
        }
    });

    return userScalable && maxScale >= 1 && minScale <= maxScale;
});

const getComputedShadow = (shadowData = {}) => {
    const shadowStyle = {};

    if ([1, 2].includes(shadowData.t)) {
        shadowStyle.boxShadow = {
            1: 'none',
            2: `${shadowData.x}px ${shadowData.y}px ${shadowData.b}px ${shadowData.c}`,
        }[shadowData.t];
    }

    return shadowStyle;
};
export const getComputedBorder = (borderData = {}) => {
    const borderStyle = {};
    const borderStyleList = ['solid', 'dotted', 'dashed'];

    if (borderData.y == 1) {
        borderStyle.border = 'none';
    } else if (borderData.y == 2) {
        let borderStr = `${borderData.w}px ${borderStyleList[borderData.s]} ${borderData.c}`;
        borderData.t && (borderStyle.borderTop = borderStr);
        borderData.r && (borderStyle.borderRight = borderStr);
        borderData.b && (borderStyle.borderBottom = borderStr);
        borderData.l && (borderStyle.borderLeft = borderStr);
    }
    return borderStyle;
};
export const getBorder = (
    { y, s, w, c, t, r, b, l, sc } = {},
    { includeHoverColor, indepControl } = { includeHoverColor: false, indepControl: true }
) => {
    const css = {};
    const cls = [];
    if (y === 1) {
        cls.push('border-none');
    } else if (y === 2) {
        const borderStyle = ['solid', 'dotted', 'dashed'][s] || 'solid';

        if (includeHoverColor) {
            createBorderCss(css, { borderData: { w, c, sc, t, r, b, l }, style: borderStyle });
        } else {
            const borderStr = `${w}px ${borderStyle} ${c}`;
            if (indepControl) {
                if (t) css.borderTop = borderStr;
                if (r) css.borderRight = borderStr;
                if (b) css.borderBottom = borderStr;
                if (l) css.borderLeft = borderStr;
            } else {
                css.border = borderStr;
            }
        }
    }
    return { css, cls };
};

function createBorderCss(css, { borderData: { w, c, sc, t, r, b, l }, style }) {
    if (t || r || b || l) {
        css.borderStyle = style;
        css['--border-color'] = c;
        css['--border-hover-color'] = sc;
        css.borderTopWidth = t ? `${w}px` : 0;
        css.borderRightWidth = r ? `${w}px` : 0;
        css.borderBottomWidth = b ? `${w}px` : 0;
        css.borderLeftWidth = l ? `${w}px` : 0;
    }
}
export const getShadow = (shadowData = {}) => {
    const cls = [];
    let css = {};
    if (shadowData.t == 1) {
        cls.push('shadow-none');
    } else if (shadowData.t == 2) {
        css.boxShadow = shadowData.x + 'px ' + shadowData.y + 'px ' + shadowData.b + 'px ' + shadowData.c;
    }

    return { cls, css };
};
export const getText = (data) => {
    const { font, prefet, colors = ['c'], alignKey, needTextAlign } = data;
    const cls = [];
    let css = {};
    if (font?.y) {
        css.fontSize = font.s + 'px';
        css.fontFamily = font.f;
        cls.push(font.i == 1 ? 'italic' : 'not-italic');
        cls.push(font.w == 1 ? 'font-bold' : 'font-normal');
        cls.push(font.d == 1 ? 'underline' : 'no-underline');
        // switch (font.ta) {
        //     case 0:
        //         cls.push('text-left');
        //         break;
        //     case 1:
        //         cls.push('text-center');
        //         break;
        //     case 2:
        //         cls.push('text-right');
        //         break;
        //     case 3:
        //         cls.push('text-justify');
        //         break;
        // }

        store.getters.addGlobalFontFamily(css, font);

        if (prefet) {
            colors.map((item) => {
                const color = font[item];
                if (color) {
                    css[prefet + item + '-color'] = color;
                    if (color.indexOf('linear-gradient') > -1) {
                        cls.push(item + '-isGradientText');
                    }
                }
            });
        } else {
            css.color = font.c;
        }
        const alignStyleList = ['left', 'center', 'right', 'justify'];

        const alignK = alignKey || 'ta';
        if (needTextAlign && isDef(font[alignK]) && alignStyleList[font[alignK]]) {
            cls.push(`text-${alignStyleList[font[alignK]]}`);
        }
    }

    return { css, cls };
};

export const getTextWrap = (wrap) => {
    const css = {};
    const cls = [];
    let { y: type = 0, l: line = 1 } = wrap;
    if (type === 0) {
        // 默认
        return { css, cls };
    } else {
        if (type === 2) {
            // 不换行
            line = 1;
        }
        cls.push('overflow-hidden', 'whitespace-normal', 'break-words', 'max-h-none');
        css.display = '-webkit-box';
        css['-webkit-line-clamp'] = line;
        css['-webkit-box-orient'] = 'vertical';
    }
    return { css, cls };
};

export const getBgScale = (t, defalutValue = '') => {
    const list = [defalutValue, 'bg-cover', 'bg-auto', 'bg-contain'];
    return list[t] || '';
};

export const getBg = function (bg = {}, isLazy = true, options = {}) {
    let srcOriginal = '';
    const cls = [];
    let css = {},
        repeatDict = ['bg-repeat', 'bg-no-repeat', 'bg-repeat-x', 'bg-repeat-y'],
        positionDict = [
            'bg-left-top',
            'bg-top',
            'bg-right-top',
            'bg-left',
            'bg-center',
            'bg-right',
            'bg-left-bottom',
            'bg-bottom',
            'bg-right-bottom',
        ],
        sizeDict = ['auto', '100% 100%', '100%', 'cover', 'contain'];

    if (bg.y === 0) {
        css.background = '';
    } else if (bg.y == 1) {
        css.background = 'none';
    } else if (bg.y == 2) {
        if (bg.d == 0) {
            //纯色
            css.backgroundColor = bg.c;
            if (options.manageV2) {
                cls.push('bg-none');
            }
        } else if (bg.d == 1) {
            //渐变色
            css.backgroundImage = 'linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            css.backgroundColor = 'initial';
        } else if (bg.d == 2) {
            if (bg.f) {
                css.backgroundSize = sizeDict[bg.s];
                if (!isLazy) {
                    css.backgroundImage = 'url(' + bg.path + ')';
                } else {
                    srcOriginal = bg.path;
                }
                css.backgroundColor = bg.c;
                if ([2, 3, 4].includes(bg.s)) {
                    cls.push('bg-no-repeat');
                } else {
                    cls.push(repeatDict[bg.r]);
                }
                if ([3, 4].includes(bg.s)) {
                    cls.push('bg-center');
                } else {
                    cls.push(positionDict[bg.p]);
                }
            }
        } else if (bg.d == 3) {
            // image
            if (bg.f) {
                if (options.manageV1) {
                    //注意，这里为了统一getComputedBg，增加的兼容变量
                    if (isLazy && !bg.tc) {
                        srcOriginal = bg.path;
                    } else if (bg.tc || !isLazy) {
                        css.backgroundImage = 'url(' + bg.path + ')';
                    }
                } else {
                    if (isLazy && !bg.tc) {
                        srcOriginal = bg.path;
                    } else {
                        css.backgroundImage = 'url(' + bg.path + ')';
                    }
                }
            }
            if (bg.tc) {
                css.backgroundImage =
                    (css.backgroundImage ? css.backgroundImage + ',' : '') +
                    'linear-gradient(' +
                    bg.a +
                    'deg, ' +
                    bg.c +
                    ' ' +
                    bg.cp +
                    '%, ' +
                    bg.tc +
                    ' ' +
                    bg.tcp +
                    '%)';
                css.backgroundColor = 'initial';
                srcOriginal = ''; //这种情况不能走懒加载
            }
            // color
            if (!bg.tc) {
                css.backgroundColor = bg.c;
            }

            if (options.manageV2) {
                if (!bg.f && !bg.tc) {
                    cls.push('bg-none');
                }
            }
            // size
            css.backgroundSize = sizeDict[bg.s];
            // repeat
            if ([2, 3, 4].includes(bg.s)) {
                cls.push('bg-no-repeat');
            } else {
                cls.push(repeatDict[bg.r]);
            }
            // position
            if ([3, 4].includes(bg.s)) {
                cls.push(positionDict[4]);
            } else if (bg.s == 1) {
                cls.push('bg-left');
            } else {
                cls.push(positionDict[bg.p]);
            }
        }
    }
    return { cls, css, srcOriginal };
};

export const getBorderRadius = function (borderRadius) {
    const cls = [];
    const css = {};
    if (borderRadius.y || (!isDef(borderRadius.y) && borderRadius.t)) {
        css.borderRadius = borderRadius.w + 'px';
    }
    return { cls, css };
};

export const addFontFaceToHead = (customFontFaceFamily, substring, resUrl) => {
    if (!customFontFaceFamily || !substring) return;
    const fontInfo = analysisCustomFontFaceFamily(customFontFaceFamily);
    let resSrc = '';
    // resUrl是在初始化的时候调用
    if (resUrl) {
        // 这里特殊处理，资源文件缺失会返回no-pic的图片-_-
        if (resUrl.includes('no-pic')) return;
        resSrc = resUrl;
    } else {
        substring = Fai.unique(substring);
        resSrc = `/font.jsp?cmd=getSubStringFont&substring=${encodeURIComponent(substring)}&id=${
            fontInfo.id
        }&_TOKEN=${$('#_TOKEN').attr('value')}`;
    }
    const fontFaceString = `
        <style type="text/css" id="${customFontFaceFamily}" subString="${substring}">
            @font-face {
                font-family: "${customFontFaceFamily}";
                src: url("${resSrc}") format("truetype");
                font-weight: normal;
                font-style: normal;
            }
        </style>
    `;

    $('head').append(fontFaceString);

    // 这里有个很坑爹的BUG，您要preload font face文件，
    // 单单设置font-family还不行，还需要设置文本，
    // 没有文本的空元素设置font-family是不会preload字体文件的，
    // 元素的属性也不能设置为none，所以在这里设置了一个元素来preload字体文件。
    if ($('#tmpPreloadFontFace').length === 0) {
        const tempFontDiv = $('<div id="tmpPreloadFontFace"></div>').css({
            height: 0,
            width: 0,
            overflow: 'hidden',
        });
        $('body').append(tempFontDiv);
    }
    $('#tmpPreloadFontFace').css('font-family', `${customFontFaceFamily}`).text(substring);
};

const analysisCustomFontFaceFamily = (fontFamily) => {
    if (!fontFamily) return {};
    const [flag, name, id, random] = fontFamily.split('__');
    return { flag, name, id, random };
};

export const Comm = {
    getModuleStyleName,
    getSysModuleNameById,
    operMultiLevelObjectAttr,
    setBitMemory,
    getBitMemory,
    mapFlag,
    bindFlag,
    objSort,
    decodeUrl,
    format,
    decodeHtmlAttr,
    encodeHtmlStr,
    decodeHtml,
    flatten,
    imageSettingtMixin,
    newsMixin,
    getComputedBg,
    extend,
    getComputedTextColor,
    createMergeFunction,
    mergeWithRule,
    LogDog,
    createUrlArgsOnclickStr,
    getStyleDataByDevice,
    getWrapStyle,
    isNewModuleAfter20210707,
    getTextAlignStyle,
    getFirstGradientColor,
    getDisplayData,
    isSectionId,
    isTemporaryModuleId,
    isTemporaryRowId,
    burnColor,
    hexToRGB,
    getComputedShadow,
};

// FIXME: 浏览器端去掉全局注册
if (typeof window !== 'undefined') {
    if (typeof window.Comm !== 'undefined') {
        Object.assign(window.Comm, Comm);
    } else {
        window.Comm = Comm;
    }
}
