export default (el, binding) => {
    const hoverStyle = binding.value;
    let disabled = binding.arg;
    if (disabled) {
        $(el).off('mouseenter.hover');
        return;
    }

    $(el)
        .off('mouseenter.hover')
        .on('mouseenter.hover', () => {
            const oldStyle = $(el).attr('style') || '';
            $(el).css(hoverStyle);
            $(el)
                .off('mouseleave.hover')
                .on('mouseleave.hover', () => {
                    $(el).attr('style', oldStyle);
                    if ($(el).attr('data-color')) {
                        $(el).css('color', $(el).attr('data-color'));
                    }
                    $(el).off('mouseleave.hover');
                });
        });
};
