import MultiLanguage from '../components/system/multiLanguage/menus/index.vue';
import { fillLanguageData } from '../components/system/multiLanguage/utils.js';
const initMultiLanguage = function (opts) {
    if (opts.multiLanguageHidden) {
        return;
    }
    let hasEl = $(opts.selector).length > 0;
    if (hasEl) {
        // 判断有没有被挂载过这个组件，如果这个id还存在的话，就说明还没被挂载到页面上，需要new Vue然后挂载
        new Vue({
            el: opts.selector,
            components: {
                MultiLanguage,
            },
            data() {
                return {
                    opts,
                };
            },
            computed: {
                list() {
                    if (opts.renderDefaultText === 0) {
                        return opts.openLanInfoList;
                    } else {
                        return fillLanguageData(opts.renderTextList, opts.openLanInfoList);
                    }
                },
                currentLan() {
                    if (opts.renderDefaultText === 0) {
                        return opts.currentLan;
                    } else {
                        return this.list.find((item) => item.lcid === opts.currentLan.lcid);
                    }
                },
            },
            template: `
                <multiLanguage
                    :list="list"
                    :currentLan="currentLan"
                    :renderType="opts.renderType"
                    :renderStyle="opts.renderStyle"
                    :isCore="opts.isCore"
                    :renderArea="opts.renderArea"
                    :fontSetting="opts.fontSetting"
                />
            `,
        });
    }
};

export { initMultiLanguage };
