var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hovermenu",
          rawName: "v-hovermenu",
          value: _vm.hovermenu,
          expression: "hovermenu",
        },
      ],
      staticClass: "top_shopping_cart_wrapper",
      class: _vm.wrapperClass,
      style: _vm.wrapperStyle,
      attrs: { projectid: "155" },
    },
    [
      _c(
        "div",
        { staticClass: "top_shopping_cart_list" },
        [
          _vm._l(_vm.onShowFuncList, function (func, index) {
            return [
              _c(
                "a",
                {
                  staticClass: "top_shopping_cart_item",
                  attrs: {
                    href: _vm.getFuncHref(func),
                    target: _vm.getFuncTarget(func),
                    rel: _vm.getFuncRel(func),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleFuncClick(func)
                    },
                  },
                },
                [
                  _vm.module.style !== 3
                    ? _c(
                        "ShoppingCartItemIcon",
                        {
                          staticClass: "top_shopping_cart_icon",
                          attrs: { item: func },
                        },
                        [
                          func.type === _vm.funcType.CART &&
                          _vm.shoppingCartSizeText != 0
                            ? _c(
                                "div",
                                { staticClass: "shopping_cart_count" },
                                [_vm._v(_vm._s(_vm.shoppingCartSizeText))]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.module.style !== 0
                    ? _c("div", { staticClass: "top_shopping_cart_text" }, [
                        _vm._v(
                          _vm._s(func.name) +
                            _vm._s(
                              func.type === _vm.funcType.CART &&
                                _vm.module.style === 3 &&
                                _vm.shoppingCartSizeText != 0
                                ? `(${_vm.shoppingCartSizeText})`
                                : ""
                            )
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  func.type === _vm.funcType.LANGUAGE
                    ? _c("LanguageList", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        ref: "languageList",
                        refInFor: true,
                        on: { onClickOutSide: _vm.closeLanguageListPopup },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.module.style === 3 && index < _vm.onShowFuncList.length - 1
                ? _c("div", { staticClass: "top_shopping_split_line" })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.manageMode
        ? _c("features", { attrs: { inner: "", features: _vm.innerFeatures } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }