import { lazyload2 } from './lazyload2.js';
import { domPortalDirective } from './domPortal.js';
import { animate } from './animate.js';
import { animateObserver } from './animateObserver.js';
import { loadingDirective } from './loading.js';
import { outsideclick } from './outsideclick';
import hoverStyle from './hoverStyle';
import { hoverStatus } from './hoverStatus';

export function registerDirectives(Vue) {
    const directives = [
        {
            name: 'lazyload2',
            directive: lazyload2,
        },
        {
            name: 'dom-portal',
            directive: domPortalDirective,
        },
        {
            name: 'animate',
            directive: animate,
        },
        {
            name: 'animate-obsever',
            directive: animateObserver,
        },
        {
            name: 'loading',
            directive: loadingDirective,
        },
        {
            name: 'outsideclick',
            directive: outsideclick,
        },
        {
            name: 'hover-style',
            directive: hoverStyle,
        },
        {
            name: 'hover-status',
            directive: hoverStatus,
        },
    ];
    directives.forEach((d) => Vue.directive(d.name, d.directive));
}
